import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React, { useRef } from "react";

export default function InputValidated({
  label,
  type,
  placeholder,
  name,
  onValueChange,
  showValidationError = false,
  defaultVal,
  required = true,
  style = "smarthost",
  step = null,
}) {
  const onChange = (value) => {
    if (onValueChange) onValueChange(value);
  };
  const inputRef = useRef();

  return (
    <div>
      <label
        htmlFor={label}
        className="block text-sm text-left font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={name}
          step={step}
          required={required}
          ref={inputRef}
          onChange={({ target }) => onChange(target?.value)}
          defaultValue={defaultVal}
          className={`peer block w-full ring-1 ring-gray-300 rounded-md border-0 py-1.5 pr-10 pl-1.5 focus:ring-${
            style === "smarthost" ? "brand" : "indigo-600"
          } invalid:focus:ring-red-500 invalid:ring-inset invalid:ring-red-300 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6`}
          placeholder={placeholder}
        />
        <div className="peer-invalid:visible invisible pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      </div>
      {showValidationError &&
        inputRef.current &&
        !inputRef.current?.validity?.valueMissing &&
        !inputRef.current?.validity?.valid && (
          <p className="text-left mt-2 text-sm text-red-600" id="error">
            {inputRef.current?.validationMessage}
          </p>
        )}
    </div>
  );
}
