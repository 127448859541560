import React from "react";
import { useParams } from "react-router-dom";
import AssetHeader from "./AssetHeader";
import useAsset from "../../hooks/useAsset";
import AssetActionRail from "./AssetActionRail";
import AssetDescriptionList from "./AssetDescriptionList";
import useAccountSettings from "../../hooks/useAccountSettings";

export default function ViewAssetPage() {
  const urlParams = useParams();
  const { asset_id } = urlParams;
  const [asset] = useAsset(asset_id);
  const [accountSettings] = useAccountSettings();

  return (
    <div className={"min-w-full px-4 sm:px-2 pb-10"}>
      <div className={"py-4"}>
        <AssetHeader
          asset={asset}
          onEditClick={() => null}
          accountSettings={accountSettings}
        />
      </div>
      <div className={"grid grid-cols-3 mt-2 sm:mt-6 gap-4"}>
        <div className={"sm:col-span-2 col-span-3 sm:-mt-4"}>
          <AssetDescriptionList
            asset={asset}
            accountSettings={accountSettings}
          />
        </div>
        <div
          className={
            "mt-2 sm:-mt-6 sm:pl-6 sm:col-span-1 sm:col-start-3 col-span-3 col-start-1"
          }
        >
          <AssetActionRail asset={asset} />
        </div>
      </div>
    </div>
  );
}
