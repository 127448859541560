export default class InvoiceService {
  async getInvoice(customerId, billingPeriodId) {
    const response = await fetch(
      `/customers/${customerId}/invoices/search?format=json&billingPeriodId=${billingPeriodId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
    return await response.json();
  }
  async getInvoices(billingPeriodId) {
    // TODO: Let's update this route to go to the billing period controller or something else, rather than invoices
    const response = await fetch(
      `/billing_periods/${billingPeriodId}/invoices?format=json`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
    return await response.json();
  }
}
