import { useEffect, useState } from "react";
import AssetService from "../services/AssetService";

export default function useAsset(assetId) {
  const [asset, setAsset] = useState();
  const svc = new AssetService();

  const getAsset = async () => {
    const a = await svc.getAsset(assetId);
    setAsset(a);
  };

  useEffect(() => {
    getAsset(assetId);
  }, []);

  const refetch = async () => {
    await getAsset(assetId);
  };

  return [asset, refetch];
}
