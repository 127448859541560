import React from "react";
import UsageTable from "../components/dashboard/UsageTable";
import StatsSection from "../components/dashboard/StatsSection";
import LinkPane from "../components/dashboard/LinkPane";
import ButtonPane from "../components/dashboard/ButtonPane";

export default function DashboardPage({
  assets,
  billing_period,
  total_usage,
  total_bill,
}) {
  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
        <ButtonPane
          title={["Expand Your Fleet ", <span>&#128176;</span>]}
          buttonText="Shop Hosting Packages"
          href={"https://cryptocaddy.us/packages"}
        >
          Add to your hash power and continue to enjoy all the benefits of smart
          billing and our industry leading uptime.
        </ButtonPane>
        <LinkPane
          title={["How Are We Doing? ", <span>&#11088;</span>]}
          href="https://forms.gle/CjK42CKD4WFA6j5s6"
          linkText="Help us Improve"
        >
          Help guide the direction of the Client Portal by providing feedback and requesting the features you want!
        </LinkPane>
      </div>
      <StatsSection
        billing_period={billing_period}
        asset_count={assets.length}
        total_usage={total_usage}
        total_bill={total_bill}
      />
      <div className="mt-8">
        <UsageTable assets={assets} />
      </div>
    </div>
  );
}
