import useCsrfToken from "./useCsrfToken";

export default function UseResetPassword() {
  const getToken = useCsrfToken();

  const reset = async (email) => {
    const response = await fetch("/reset-password?format=json", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    });
    return await response.json();
  };

  return reset;
}
