import React from "react";
import Tooltip from "../../../components/Tooltip"

const stats = [
  { name: "Period Usage To Date", stat: "" },
  { name: "Machine Count", stat: "" },
  { name: "Billing Period Length", stat: "" },
];

export default function StatsSection({ billing_period, asset_count, total_usage, total_bill }) {
  const getStats = () => {
    if (!billing_period) return stats;

    let total_usage_text = "";
    if (total_usage > 1000) {
      total_usage_text = `${(total_usage / 1000).toFixed(2)} MWh`
    } else {
      total_usage_text = `${(total_usage).toFixed(2)} kWh`
    }

    stats[0].stat = `$${total_bill} (${total_usage_text})`;
    stats[1].stat = asset_count;
    stats[2].stat = billing_period.number_of_days + " days";
    return stats;
  };

  return (
    <div>
      <Tooltip content={`${billing_period.starts_at} - ${billing_period.ends_at} ${billing_period.ending_year}`}>
        <h3 className="w-auto text-xl font-semibold leading-6 text-gray-900">
          Current Billing Period (ending {billing_period.ends_at})
        </h3>
      </Tooltip>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {getStats().map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <dt className="truncate text-sm font-medium text-gray-500">
              {item.name}
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
