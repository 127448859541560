import React from "react";
import Dropdown from "../../components/Dropdown";

export const ImpersonateCustomerDropdown = ({ options, setActiveCustomer }) => {
  return (
    <div className="bg-red-100 rounded-md p-2">
      <Dropdown label={"Impersonate User"} items={options.map(x => ({ value: x.email, title: x.name }))} onSelectionChange={(item) => setActiveCustomer(item.value)} includeBlank={true} />
    </div>
  );
};

export default ImpersonateCustomerDropdown;
