export default function useUser() {
  return [
    {
      first_name: "Colin",
      last_name: "Monroe",
      email: "colin@cryptocaddy.us",
      company_name: "CryptoCaddy",
    },
  ];
}
