export default class CustomerService {
  async getCustomers() {
    const response = await fetch(`/customers_new?format=json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  }
  async getCustomer(id) {
    const response = await fetch(`/customers_new/${id}?format=json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  }
}
