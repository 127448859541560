import React from "react";
import Dropdown from "../../components/Dropdown";

export const AccountDropdown = ({ teams, setActiveTeam }) => {
  return (
    <>
      {teams.length > 1 && (
        <Dropdown label={"Account"} items={teams.map(x => ({ value: x.id, title: x.name, subtitle: `${x.assets.length} assets` }))} onSelectionChange={(item) => setActiveTeam(item.value)} />
      )
      }
    </>
  );
};

export default AccountDropdown;
