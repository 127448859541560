import React from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import LinkPane from "../dashboard/LinkPane";

export default function SupportPane() {
  return (
    <div className="flex flex-col max-w-4xl">
      <div className="max-w-xl">
        <LinkPane
          title={"Coming Soon!"}
          linkText="Email Support Team"
          href="mailto:support@cryptocaddy.us"
        >
          Until our new support system is available please continue to contact us at{" "}
          <u>
            <a className="text-blue-600" href="mailto:support@cryptocaddy.us">
              support@cryptocaddy.us
            </a>
          </u>{" "}
          for any hosting support.
        </LinkPane>
      </div>
      {/* <span className="self-end text-center w-[40] px-2 py relative -bottom-8 right-2 opacity-100 inline-flex items-center rounded-full bg-green-200 text-md font-medium text-green-900">
        COMING SOON
      </span>
      <div className="text-center border-2 border-gray-300 rounded px-4 opacity-25 bg-gray-white drop-shadow-sm">
        <div className="p-4">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No support tickets
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new support ticket.
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="cursor-default inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              New Ticket
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
