import { classNames } from "../utility/Utility";

import React from "react";

export default function WarrantyStatusBadge({ asset }) {
  let className = "text-green-700 bg-green-50 ring-green-600/20";
  let name = "In Warranty";

  // Intentional double eq here, as we want to catch both null and undef
  if (asset.warranty_expired == null) {
    className = "text-gray-600 bg-gray-50 ring-gray-500/10";
    name = "Unknown";
  } else if (asset.warranty_expired) {
    className = "text-yellow-700 bg-yellow-50 ring-yellow-600/10";
    name = "Warranty Expired";
  }

  return (
    <div
      className={classNames(
        className,
        "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-fit",
      )}
    >
      {name}
    </div>
  );
}
