export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getCsrfToken = () => {
  const node = document.querySelector("meta[name=csrf-token]");
  return node?.content;
};

export const QUOTE_DRAFT = "draft";
export const QUOTE_SIGNED = "signed";
export const QUOTE_PAID = "paid";
export const QUOTE_ORDERED = "ordered";
export const QUOTE_CLOSED = "closed";

export const QUOTE_STATES = [
  QUOTE_DRAFT,
  QUOTE_SIGNED,
  QUOTE_PAID,
  QUOTE_ORDERED,
  QUOTE_CLOSED,
];

export const DATE_FORMAT = "MM/DD/YYYY";
