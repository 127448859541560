import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import NewQuoteForm from "./NewQuoteForm";
import RailsForm from "../../components/RailsForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import ButtonGroup from "../../components/ButtonGroup";
import { ExistingCustomerQuoteForm } from "./ExistingCustomerQuoteForm";

export default function NewQuoteModal({ modalOpen = false, setOpen }) {
  const EXISTING_KEY = "existing";
  const NEW_KEY = "new";
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [selectedKey, setSelectedKey] = useState(NEW_KEY);
  const buttons = [
    { text: "New Customer", key: NEW_KEY },
    {
      text: "Existing Customer",
      key: EXISTING_KEY,
    },
  ];

  const onQuoteTypeChange = (selectedButton) => {
    setSelectedKey(selectedButton.key);
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative lg:min-h-[456px] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <RailsForm action={"/quotes"} method={"POST"}>
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Create a New Quote
                      </Dialog.Title>
                      <div className="my-2">
                        <ButtonGroup
                          buttons={buttons}
                          selectedKey={selectedKey}
                          onButtonPress={onQuoteTypeChange}
                        />
                        {selectedKey === NEW_KEY && (
                          <NewQuoteForm setValid={setValid} />
                        )}
                        {selectedKey === EXISTING_KEY && (
                          <ExistingCustomerQuoteForm setValid={setValid} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      disabled={!valid}
                      className="inline-flex disabled:bg-gray-200 w-full justify-center rounded-md bg-brand hover:bg-brand-hover px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand sm:col-start-2"
                      onClick={() => setLoading(true)}
                    >
                      {!loading ? "Create" : <LoadingIndicator />}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </RailsForm>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
