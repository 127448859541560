import React from "react";
import { PencilIcon } from "@heroicons/react/20/solid";
import LoadingIndicator from "../../components/LoadingIndicator";
import Breadcrumbs from "../../components/Breadcrumbs";
import AssetUiButton from "../../components/AssetUiButton";
import moment from "moment";
import { DATE_FORMAT } from "../../utility/Utility";

export default function AssetHeader({ asset, onEditClick, accountSettings }) {
  if (!asset) {
    return <LoadingIndicator />;
  }

  const customer = asset.customer;
  const contract = asset.contract;
  const operationsEnabled = accountSettings?.features?.operations;

  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <Breadcrumbs
          crumbs={[
            { name: "Customers", href: "/customers_new" },
            {
              name: `${customer.name}`,
              href: `/customers_new/${customer.id}`,
            },
            {
              name: `${contract.display_name}`,
              href: `/customers_new/${customer.id}/contracts/${contract.id}`,
            },
            {
              name: `Asset ${asset.asset_number}`,
              href: `#`,
            },
          ]}
        />
        <h2 className="mt-4 pb-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Asset {asset.asset_number}
          {operationsEnabled && (
            <span className={"ml-2"}>
              <AssetUiButton asset={asset} />
            </span>
          )}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            Created on {moment(asset.created_at).format(DATE_FORMAT)}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        <span className="sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={onEditClick}
          >
            <PencilIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Edit
          </button>
        </span>
      </div>
    </div>
  );
}
