import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utility/Utility";
import useCustomers from "../../hooks/useCustomers";
import { Link } from "react-router-dom";
import moment from "moment";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function CustomerList({ filterTerm }) {
  const [customers] = useCustomers();
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  useEffect(() => {
    if (filterTerm) {
      setFilteredCustomers(
        customers.filter(
          (c) =>
            c.name?.toLowerCase()?.includes(filterTerm.toLowerCase()) ||
            c.company_name?.toLowerCase()?.includes(filterTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredCustomers(customers);
    }
  }, [customers, filterTerm]);

  if (!customers)
    return (
      <div className={"min-w-full mt-12 text-center"}>
        <LoadingIndicator />
      </div>
    );

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {filteredCustomers?.map((customer) => (
        <li
          key={customer.id}
          className="flex items-center justify-between gap-x-6 py-5"
        >
          <div className="min-w-0">
            <div className="flex items-start gap-x-1 sm:gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900 whitespace-nowrap">
                {customer.name}
              </p>
              {customer.company_name && (
                <p
                  className={classNames(
                    "text-blue-700 bg-blue-50 ring-blue-600/20",
                    "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset truncate",
                  )}
                >
                  {customer.company_name}
                </p>
              )}
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                {customer.machine_count}{" "}
                {customer.machine_count === 1 ? "machine" : "machines"}
              </p>
              <svg
                viewBox="0 0 2 2"
                className="h-0.5 w-0.5 fill-current hidden sm:block"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate hidden sm:block">
                Created on {moment(customer.created_at).format("LL")}
              </p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <Link
              to={customer?.id?.toString()}
              reloadDocument
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
            >
              View <span className={"hidden sm:inline"}>customer</span>
              <span className="sr-only">, {customer.name}</span>
            </Link>
            {/*<Menu as="div" className="relative flex-none hidden sm:block">*/}
            {/*  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">*/}
            {/*    <span className="sr-only">Open options</span>*/}
            {/*    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />*/}
            {/*  </Menu.Button>*/}
            {/*  <Transition*/}
            {/*    as={Fragment}*/}
            {/*    enter="transition ease-out duration-100"*/}
            {/*    enterFrom="transform opacity-0 scale-95"*/}
            {/*    enterTo="transform opacity-100 scale-100"*/}
            {/*    leave="transition ease-in duration-75"*/}
            {/*    leaveFrom="transform opacity-100 scale-100"*/}
            {/*    leaveTo="transform opacity-0 scale-95"*/}
            {/*  >*/}
            {/*    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">*/}
            {/*      <Menu.Item>*/}
            {/*        {({ active }) => (*/}
            {/*          <a*/}
            {/*            href="#"*/}
            {/*            className={classNames(*/}
            {/*              active ? "bg-gray-50" : "",*/}
            {/*              "block px-3 py-1 text-sm leading-6 text-gray-900",*/}
            {/*            )}*/}
            {/*          >*/}
            {/*            Edit<span className="sr-only">, {customer.name}</span>*/}
            {/*          </a>*/}
            {/*        )}*/}
            {/*      </Menu.Item>*/}
            {/*      <Menu.Item>*/}
            {/*        {({ active }) => (*/}
            {/*          <a*/}
            {/*            href="#"*/}
            {/*            className={classNames(*/}
            {/*              active ? "bg-gray-50" : "",*/}
            {/*              "block px-3 py-1 text-sm leading-6 text-gray-900",*/}
            {/*            )}*/}
            {/*          >*/}
            {/*            Move<span className="sr-only">, {customer.name}</span>*/}
            {/*          </a>*/}
            {/*        )}*/}
            {/*      </Menu.Item>*/}
            {/*      <Menu.Item>*/}
            {/*        {({ active }) => (*/}
            {/*          <a*/}
            {/*            href="#"*/}
            {/*            className={classNames(*/}
            {/*              active ? "bg-gray-50" : "",*/}
            {/*              "block px-3 py-1 text-sm leading-6 text-gray-900",*/}
            {/*            )}*/}
            {/*          >*/}
            {/*            Delete<span className="sr-only">, {customer.name}</span>*/}
            {/*          </a>*/}
            {/*        )}*/}
            {/*      </Menu.Item>*/}
            {/*    </Menu.Items>*/}
            {/*  </Transition>*/}
            {/*</Menu>*/}
          </div>
        </li>
      ))}
    </ul>
  );
}
