import React, { useEffect, useState } from "react";

export default function FileUpload({ text }) {
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileUrl, setSelectedFileUrl] = useState();

  useEffect(() => {
    if (selectedFile) {
      setSelectedFileUrl(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  return (
    <div className={"flex flex-col"}>
      <label
        htmlFor={"logo"}
        className={
          "w-48 text-sm text-center border border-brand text-brand cursor-pointer rounded-md py-2 px-4"
        }
      >
        {text}
      </label>
      <input
        className={"hidden"}
        type="file"
        id="logo"
        name="logo"
        accept="image/png, image/jpeg"
        onChange={(e) => setSelectedFile(e?.target?.files[0])}
      />
      {selectedFileUrl && (
        <>
          <img
            className="p-4 max-h-60"
            src={selectedFileUrl}
            alt={"uploaded logo"}
          />
          <p className={"text-xs text-gray-400 text-center"}>
            {selectedFile.name}
          </p>
        </>
      )}
    </div>
  );
}
