import React, { useEffect, useState } from "react";
import InputValidated from "../../components/InputValidated";
import Dropdown from "../../components/Dropdown";
import useCustomers from "../../hooks/useCustomers";
import LoadingIndicator from "../../components/LoadingIndicator";

const DEFAULT_RATE = 0.08;
const DEFAULT_TERM = 1;

export const ExistingCustomerQuoteForm = ({ setValid }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [email, setEmail] = useState(null);
  const [rate, setRate] = useState(DEFAULT_RATE);
  const [term, setTerm] = useState(DEFAULT_TERM);

  const [customers, refetch] = useCustomers();
  useEffect(() => {
    const valid = firstName && lastName && email && rate && term;
    setValid(valid);
  }, [firstName, lastName, email, rate, term]);

  const onCustomerSelected = (selected) => {
    const customer = customers.find((x) => x.id === selected.value);
    console.log(customer);
    if (!customer) {
      return;
    }
    setFirstName(customer.first_name);
    setLastName(customer.last_name);
    setCompanyName(customer.company_name);
    setEmail(customer.email);
  };

  if (!customers) {
    return (
      <div className={"my-4"}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={"space-y-12 my-4"}>
      <div className={"grid grid-cols-1 gap-y-4"}>
        <input hidden name={"quote[version]"} value={"2"} />
        <input hidden name={"quote[first_name]"} value={firstName} />
        <input hidden name={"quote[last_name]"} value={lastName} />
        <input hidden name={"quote[company_name]"} value={companyName} />
        <input hidden name={"quote[email]"} value={email} />
        <label className="block text-sm text-left font-medium leading-6 text-gray-900">
          Select customer
        </label>
        <Dropdown
          fullWidth={true}
          includeBlank={true}
          style={"smarthost"}
          items={customers.map((customer) => ({
            title: customer.name,
            subtitle: customer.company_name,
            value: customer.id,
          }))}
          onSelectionChange={onCustomerSelected}
        />
        <InputValidated
          onValueChange={(value) => setRate(value)}
          label={"Rate ($ / kWh)"}
          name={"quote[rate_kwh]"}
          defaultVal={DEFAULT_RATE}
          type={"number"}
          step={0.000001}
        />
        <InputValidated
          onValueChange={(value) => setTerm(value)}
          label={"Term (years)"}
          name={"quote[term]"}
          defaultVal={DEFAULT_TERM}
          type={"number"}
          step={0.1}
        />
      </div>
    </div>
  );
};

export default ExistingCustomerQuoteForm;
