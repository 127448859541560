import React, { useState } from "react";
import RailsForm from "../../components/RailsForm";
import { Helmet } from "react-helmet";
import SimpleSelect from "../../components/SimpleSelect";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";
import InputValidated from "../../components/InputValidated";

export default function RenewalSurvey({ assets }) {
  const [showOffer, setShowOffer] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  return (
    <>
      <Helmet>
        <title>Smart Host | Contract Renewal</title>
      </Helmet>
      <div className="flex min-h-full flex-col justify-center bg-gray-50 py-8 lg:px-8 -mx-4 px-4">
        <div className={"flex items-center mx-auto"}>
          <div className="sm:mx-2 sm:w-full sm:max-w-screen-2xl">
            <img
              className="mx-auto h-16 w-auto text-center"
              src="/images/cryptocaddy_inverted.png"
              alt="CryptoCaddy Logo"
            />
            <h2 className="mt-4 text-center text-2xl font-bold tracking-tight text-gray-900">
              Contract Renewal Survey
            </h2>
            <div
              className={
                "flex flex-col lg:flex-none lg:grid max-h-fit lg:max-h-none lg:h-auto grid-cols-1 lg:grid-cols-7 gap-y-2 lg:gap-y-0 lg:gap-x-2 mt-4"
              }
            >
              <div
                className={
                  "lg:col-span-4 text-sm bg-white shadow rounded-md px-4 py-6"
                }
              >
                <p className="text-left leading-loose text-gray-900 ">
                  Hey there!
                </p>
                <p className="mt-2 text-left  leading-loose text-gray-900 max-w-2xl">
                  Our records show that your contract (for the machines listed
                  below) is scheduled to end this month. Please fill out the
                  information table below so we can get all of your paperwork
                  processed quickly.
                </p>
                <p className="mt-4 text-left leading-loose text-gray-900 max-w-2xl">
                  For any questions, please contact{" "}
                  <a
                    href={"mailto:support@cryptocaddy.us"}
                    className={
                      "underline text-indigo-600 hover:text-indigo-500"
                    }
                  >
                    support@cryptocaddy.us
                  </a>
                  .
                </p>
              </div>
              <div
                className={`bg-white shadow rounded-md px-4 py-6 lg:col-span-3 lg:min-h-full min-w-full ${
                  showOffer ? "h-auto" : "h-24"
                } lg:min-w-none lg:min-h-none lg:h-56`}
              >
                <div className={"flex flex-row justify-between"}>
                  <p
                    className={
                      "font-bold tracking-wide uppercase text-lg text-indigo-600"
                    }
                  >
                    Trade Up Program
                  </p>
                  <button
                    className={
                      "p-1 focus:ring-gray-500 focus:ring-2 focus:ring-offset-2 focus:outline-none rounded-md lg:hidden"
                    }
                    onClick={() => setShowOffer(!showOffer)}
                  >
                    {showOffer ? (
                      <ChevronUpIcon
                        className={"h-6 w-6 text-gray-500"}
                      ></ChevronUpIcon>
                    ) : (
                      <ChevronDownIcon
                        className={"h-6 w-6 text-gray-500"}
                      ></ChevronDownIcon>
                    )}
                  </button>
                </div>
                <p className={"text-sm text-gray-500 leading-loose"}>
                  Limited Time Offer
                </p>
                <div
                  className={`transition duration-300 ease-in-out delay-150 ${
                    showOffer ? "block" : "hidden"
                  } lg:block`}
                >
                  <p className={"text-sm text-gray-900 mt-2 leading-loose"}>
                    Our Trade Up program allows you to trade in your current
                    machine in exchange for a credit (and $100 discount!)
                    towards the purchase of a new S21 miner.
                  </p>
                  <p className={"text-sm text-gray-900 mt-2 leading-loose"}>
                    If you select &apos;Yes&apos; using the form below, we will
                    reach out to you with details on pricing and trade-in value.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 mx-auto w-full sm:max-w-screen-2xl">
          <RailsForm
            className="space-y-6"
            action="/surveys/renewal"
            method="POST"
          >
            <>
              <div className="sm:bg-white py-6 px-4 sm:shadow sm:rounded-lg sm:px-10 overflow-scroll">
                <input type={"hidden"} value={token} name={"form_token"} />
                <table
                  className={
                    "border-0 rounded-md p-4 border-collapse border-gray-500 w-full"
                  }
                >
                  <thead>
                    <tr>
                      <th
                        className={
                          "whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0"
                        }
                      >
                        Asset #
                      </th>
                      <th
                        className={
                          "whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0"
                        }
                      >
                        Serial
                      </th>
                      <th
                        className={
                          "whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0"
                        }
                      >
                        Model
                      </th>
                      <th
                        className={
                          "whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-700"
                        }
                      >
                        Renew this machine?
                      </th>
                      <th
                        className={
                          "whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-700"
                        }
                      >
                        Interested in Trade Up?
                      </th>
                    </tr>
                  </thead>
                  <tbody className={"divide-y divide-gray-200 lg:bg-white"}>
                    {assets.map((asset) => {
                      return (
                        <tr key={asset.id} className={"hover:bg-gray-50"}>
                          <td className={"whitespace-nowrap py-2 pr-2 text-sm"}>
                            {asset.asset_number}
                          </td>
                          <td className={"whitespace-nowrap py-2 pr-2 text-sm"}>
                            {asset.serial_number}
                          </td>
                          <td className={"whitespace-nowrap py-2 pr-2 text-sm"}>
                            {asset.miner_configuration?.name}
                          </td>
                          <td className={"whitespace-nowrap py-2 pr-2 text-sm"}>
                            <SimpleSelect
                              style={"cryptocaddy"}
                              includeBlank={true}
                              defaultValue={""}
                              name={`assets[${asset.id}][renewal]`}
                              options={[
                                {
                                  label:
                                    "Yes, I would like to renew this machine.",
                                  value: "yes",
                                },
                                {
                                  label:
                                    "No, I don't want to renew this machine.",
                                  value: "no",
                                },
                              ]}
                            />
                          </td>
                          <td className={"whitespace-nowrap py-2 text-sm pr-4"}>
                            <SimpleSelect
                              style={"cryptocaddy"}
                              includeBlank={true}
                              defaultValue={""}
                              name={`assets[${asset.id}][trading_up]`}
                              options={[
                                {
                                  label:
                                    "Yes, I am interested in the Trading Up program.",
                                  value: "yes",
                                },
                                {
                                  label:
                                    "No, I don't want to trade in this machine.",
                                  value: "no",
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className={"flex flex-row items-start lg:items-end w-full"}>
                <InputValidated
                  style={"cryptocaddy"}
                  includeBlank={true}
                  label={"Please enter your preferred term length (in months)"}
                  defaultValue={""}
                  name={`term_preference_weeks`}
                  type={"number"}
                  step={1}
                />
                <div className={"lg:block hidden flex-1"}></div>
              </div>
              <button
                type="submit"
                className="max-w-40 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              >
                Submit Form
              </button>
            </>
          </RailsForm>
        </div>
      </div>
    </>
  );
}
