import React, { useMemo, useState } from "react";
import RailsForm from "../../components/RailsForm";
import WelcomeModal from "./WelcomeModal";
import SelectMenu from "../../components/SelectMenu";
import moment from "moment";
import FileUpload from "../../components/FileUpload";

const billingDayOptions = () => {
  return [...Array(28).keys()].map((day) => ({
    value: day + 1,
    label: day + 1,
  }));
};

export default function SetupAccount({ account }) {
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(1);
  const periodStartMoment = useMemo(
    () =>
      moment([
        moment().add(1, "months").year(),
        moment().add(1, "months").month(),
        selectedDay,
      ]),
    [selectedDay],
  );
  const periodEndMoment = useMemo(
    () => periodStartMoment.clone().add(1, "months").subtract(1, "days"),
    [selectedDay],
  );
  const periodInvoiceMoment = useMemo(
    () => periodStartMoment.clone().add(1, "months"),
    [selectedDay],
  );

  return (
    <>
      {showWelcomeModal && (
        <WelcomeModal
          setOpen={setShowWelcomeModal}
          open={showWelcomeModal}
          title={"Welcome to Smart Host!"}
          body={
            "Your account is now created. Finish setting up your account here and then get started!"
          }
        />
      )}
      <div className="flex min-h-full flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl">
          <h2 className="mt-4 text-center text-2xl font-bold tracking-tight text-gray-900">
            Finish setting up your account for {account?.name}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-400">
            Let's finish off the last of our paperwork before we dive in.
          </p>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10">
            <RailsForm
              className="space-y-6"
              action="/accounts/setup"
              method="POST"
            >
              <div>
                <label
                  htmlFor="billing_period_day"
                  className="block text-sm font-medium text-gray-900"
                >
                  Select Billing Day of Month
                </label>
                <p
                  className="mt-2 text-sm text-gray-500"
                  id="email-description"
                >
                  This is the day each month your invoices will get generated
                  for. It also defines the start day of each new billing period.
                </p>
                <div className="mt-1">
                  <SelectMenu
                    options={billingDayOptions()}
                    inputName={"billing_period_day"}
                    preselectedOption={billingDayOptions()[0]}
                    onChange={(val) => setSelectedDay(val?.value)}
                  />
                </div>
                <p
                  className="mt-2 text-sm text-gray-500"
                  id="email-description"
                >
                  <em>
                    Next invoice date:{" "}
                    {periodInvoiceMoment.format("MMM D, YYYY")}.
                    <br />
                    Next billing period:{" "}
                    {periodStartMoment.format("MMM D, YYYY")} -{" "}
                    {periodEndMoment.format("MMM D, YYYY")}.
                  </em>
                </p>
              </div>
              <div>
                <label
                  htmlFor="brand_color"
                  className="block text-sm font-medium text-gray-700"
                >
                  Brand Color
                </label>
                <div className="mt-1">
                  <input
                    id="brand_color"
                    name="brand_color"
                    type="color"
                    required
                    className={"w-full h-12 px-2 py-1"}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Logo
                </label>
                <div className="mt-4">
                  <FileUpload text={"Upload your logo"} />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-brand py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-hover focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                >
                  Save and Continue
                </button>
              </div>
            </RailsForm>
          </div>
        </div>
      </div>
    </>
  );
}
