import { PencilIcon } from "@heroicons/react/20/solid";
import React from "react";
import { usdFormatter } from "../../utility/Utility";
import { EyeIcon } from "@heroicons/react/24/outline";

export default function InvoiceGrid({ invoices = [], isFinal }) {
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
    >
      {invoices.map((invoice) => (
        <li
          key={invoice?.id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
        >
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {invoice.customer.name}
                </h3>
                {!isFinal && (
                  <span className="inline-flex flex-shrink-0 items-center rounded-full bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                    {/*Invoice #{invoice.id}*/}
                    Preview
                  </span>
                )}
              </div>
              {invoice.customer.company_name && (
                <p className="mt-1 truncate text-sm text-gray-500">
                  {invoice.customer.company_name}
                </p>
              )}
            </div>
            <p>{usdFormatter.format(invoice.total)}</p>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a
                  href={`customers_new/${invoice.customer.id}/billing`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PencilIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Edit
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <a
                  href={`/invoices_new/${invoice.id}?format=pdf${
                    !isFinal ? "&preview=true" : ""
                  }`}
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  View
                </a>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
