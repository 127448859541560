import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { ArrowDownCircleIcon, PlusSmallIcon } from "@heroicons/react/20/solid";
import NewQuoteModal from "./NewQuoteModal";
import { days, secondaryNavigation } from "./Data";
import { Link } from "react-router-dom";
import { classNames, usdFormatter } from "../../utility/Utility";
import QuoteStatusBadge from "../../components/QuoteStatusBadge";
import useQuotes from "../../hooks/useQuotes";
import { Helmet } from "react-helmet";
import useQuotesDashboard from "../../hooks/useQuotes";

const SecondaryNavigation = ({ setModalOpen }) => {
  return (
    <header className="pb-4 pt-6 sm:pb-6">
      <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
        <h1 className="text-base font-semibold leading-7 text-gray-900">
          Quotes
        </h1>
        <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
          {secondaryNavigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={item.current ? "text-brand" : "text-gray-700"}
            >
              {item.name}
            </a>
          ))}
        </div>
        <button
          className="ml-auto flex items-center gap-x-1 rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
          onClick={() => setModalOpen(true)}
        >
          <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
          New quote
        </button>
      </div>
    </header>
  );
};

export const QuotesPage = (props) => {
  const { miner_configurations: minerConfigurations } = props;

  const getStats = (stats) => {
    return (
      <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
          {stats.map((stat, statIdx) => (
            <div
              key={stat.name}
              className={classNames(
                statIdx % 2 === 1
                  ? "sm:border-l"
                  : statIdx === 2
                  ? "lg:border-l"
                  : "",
                "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8",
              )}
            >
              <dt className="text-sm font-medium leading-6 text-gray-500">
                {stat.name}
              </dt>
              {/*<dd*/}
              {/*  className={classNames(*/}
              {/*    stat.change_type === "negative"*/}
              {/*      ? "text-rose-600"*/}
              {/*      : "text-emerald-600",*/}
              {/*    "text-xs font-medium",*/}
              {/*  )}*/}
              {/*>*/}
              {/*  {stat.change}*/}
              {/*</dd>*/}
              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                {stat.type === "money"
                  ? usdFormatter.format(stat.value)
                  : stat.type === "percent"
                  ? `${(stat.value * 100).toFixed(2)}%`
                  : stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    );
  };

  const getActivityTable = (quotes) => {
    return (
      <div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
            Recent activity
          </h2>
        </div>
        <div className="mt-6 overflow-hidden border-t border-gray-100">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <table className="w-full text-left">
                <thead className="sr-only">
                  <tr>
                    <th>Amount</th>
                    <th className="hidden sm:table-cell">Client</th>
                    <th>More details</th>
                  </tr>
                </thead>
                <tbody>
                  {days
                    .filter((d) => quotes.some((q) => q.day === d)) // this is quite messy, we should materialize all this somewhere first
                    .map((day) => (
                      <Fragment key={day}>
                        <tr className="text-sm leading-6 text-gray-900">
                          <th
                            scope="colgroup"
                            colSpan={3}
                            className="relative isolate py-2 font-semibold"
                          >
                            <time>{day}</time>
                            <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                            <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                          </th>
                        </tr>
                        {quotes
                          .filter((q) => q.day === day)
                          .map((quote) => (
                            <tr key={quote.id}>
                              <td className="relative py-5 pr-6">
                                <div className="flex gap-x-6">
                                  <div className={"flex-none"}>
                                    <a
                                      href={`/quotes/${quote.id}?format=pdf&smarthost=true`}
                                      target={"_blank"}
                                      rel={"noreferrer"}
                                    >
                                      <ArrowDownCircleIcon
                                        className="hidden h-8 w-6 text-gray-400 sm:block"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                  {/*<div className={"flex-none mr-2"}>*/}
                                  {/*  <div className="text-sm font-medium leading-6 text-gray-900">*/}
                                  {/*    Ordered on {quote.order_date}*/}
                                  {/*  </div>*/}
                                  {/*  <div className="mt-1 text-xs leading-5 text-gray-500">*/}
                                  {/*    Online by {quote.estimated_online_date}*/}
                                  {/*  </div>*/}
                                  {/*</div>*/}
                                  <div className="flex-auto">
                                    <div className="flex items-start gap-x-3">
                                      <div className="text-sm font-medium leading-6 text-gray-900">
                                        {usdFormatter.format(quote.total)}
                                      </div>
                                      <QuoteStatusBadge
                                        state={quote.aasm_state}
                                      />
                                    </div>
                                    {quote.machine_count ? (
                                      <div className="mt-1 text-xs leading-5 text-gray-500">
                                        {quote.machine_count} machines
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                              </td>
                              <td className="hidden py-5 pr-6 sm:table-cell">
                                <div className="text-sm leading-6 text-gray-900">
                                  {quote.name}
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  {quote.company}
                                </div>
                              </td>
                              <td className="py-5 text-right">
                                <div className="flex justify-end">
                                  <Link
                                    to={`${quote.id}`}
                                    className="text-sm font-medium leading-6 text-brand hover:text-cyan-700"
                                  >
                                    View
                                    <span className="hidden sm:inline">
                                      {" "}
                                      details
                                    </span>
                                  </Link>
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  Quote{" "}
                                  <span className="text-gray-900">
                                    #{quote.id}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [data, refetch] = useQuotesDashboard();

  const { quotes, stats } = data;

  return (
    <>
      <Helmet>
        <title>Smart Host | Quotes</title>
      </Helmet>
      <div className="relative isolate overflow-hidden">
        {/* Secondary navigation */}
        <SecondaryNavigation setModalOpen={setModalOpen} />
        {/* Stats */}
        {getStats(stats || [])}
      </div>
      <div className="space-y-16 py-16 xl:space-y-20">
        {/* Recent activity table */}
        {getActivityTable(quotes || [])}
        {/* Recent client list*/}
      </div>
      <NewQuoteModal modalOpen={modalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default QuotesPage;
