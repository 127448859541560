export default class OperationsService {
  async getOperations() {
    const response = await fetch(`/worker_statuses?format=json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  }

  async getUnmappedWorkers() {
    const response = await fetch(`/worker_statuses/unmapped?format=json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  }
}
