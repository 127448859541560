import { getCsrfToken } from "../utility/Utility";

export default function useEditQuoteLine() {
  return async (id, body) => {
    const response = await fetch(`/quote_lines/${id}?format=json`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        authenticity_token: getCsrfToken(),
        ...body,
      }),
    });
    return await response.json();
  };
}
