import { useEffect, useState } from "react";
import QuoteService from "../services/QuoteService";

export default function useQuote(quoteId) {
  const [quote, setQuote] = useState({});
  const svc = new QuoteService();

  const getQuote = async (id) => {
    const q = await svc.getQuote(id);
    setQuote(q);
  };

  useEffect(() => {
    getQuote(quoteId);
  }, [quoteId]);

  const refetch = async () => {
    await getQuote(quoteId);
  };

  return [quote, refetch];
}
