import React, { useState } from "react";
import SearchableDropdown from "../../../components/SearchableDropdown";
import { usdFormatter } from "../../../utility/Utility";
import { Button } from "../../../components/Button";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import RowInput from "./RowInput";

const RowCheckBox = ({ name, label, onValueChanged }) => {
  return (
    <>
      <div className="flex h-6 items-center">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-brand focus:ring-brand"
          onChange={(ev) => onValueChanged(ev?.target?.checked)}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={name} className="font-medium text-gray-900">
          {label}
        </label>
      </div>
    </>
  );
};

export default function MinerEditForm({
  minerQuoteItem,
  onCancel,
  onSave,
  onDelete,
  minerConfigurations,
  showDelete,
  showFeeOptions,
}) {
  const [selectedMinerId, setSelectedMinerId] = useState(
    minerQuoteItem?.miner_configuration?.id,
  );
  const [quantity, setQuantity] = useState(minerQuoteItem?.quantity ?? 1);
  const [price, setPrice] = useState(minerQuoteItem?.price ?? 0);
  const [overclocked, setOverclocked] = useState(false);
  const [installFee, setInstallFee] = useState(false);
  const [overclockFee, setOverclockFee] = useState(false);
  const [serviceFee, setServiceFee] = useState(false);
  const [loading, setLoading] = useState(false);

  const onComplete = () => {
    setLoading(true);
    const m = {
      ...minerQuoteItem,
      quantity: quantity,
      price: price,
      miner_configuration_id: selectedMinerId,
      overclocked: overclocked,
      installation_fee: installFee ? "1" : "0", // Fun rails backwards compat
      overclocking_fee: overclockFee ? "1" : "0", // Fun rails backwards compat
      service_fee: serviceFee ? "1" : "0", // Fun rails backwards compat
    };
    if (onSave) onSave(m);
    setLoading(false);
  };

  return (
    <div className={"flex flex-col justify-center"}>
      <div className="flex -space-x-px my-6">
        <div className={"min-w-[350px]"}>
          <label htmlFor="location" className="text-gray-500">
            Miner Type
          </label>
          <SearchableDropdown
            options={minerConfigurations}
            className={"focus:z-10"}
            preSelectedId={selectedMinerId}
            onSelectedId={setSelectedMinerId}
            displayField={"name"}
            showLabel={false}
            idField={"id"}
            secondaryLabelFn={(miner) =>
              `${miner.manufacturer} | ${miner.hash_rate} ${miner.hash_rate_units}`
            }
            filterFn={(option, query) => {
              return (
                option.name.toLowerCase().includes(query.toLowerCase()) ||
                option.hash_rate_units
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                option.manufacturer.toLowerCase().includes(query.toLowerCase())
              );
            }}
          />
        </div>
        <RowInput
          name={"quantity"}
          placeholder={"1"}
          label={"Quantity"}
          type={"number"}
          value={quantity}
          onChange={setQuantity}
        />
        <RowInput
          name={"price"}
          placeholder={"$2,500"}
          type={"number"}
          label={"Price"}
          value={price}
          onChange={setPrice}
        />
        <RowInput
          name={"total"}
          value={`${usdFormatter.format(quantity * price)}`}
          label={"Total"}
          disabled={true}
        />
      </div>
      {showFeeOptions && (
        <div className={"flex flex-row mb-3 justify-end space-x-3"}>
          <RowCheckBox
            name={"overclocked"}
            label={"Overclocked"}
            onValueChanged={(value) => setOverclocked(value)}
          />
          <RowCheckBox
            name={"install_fee"}
            label={"Add Install Fee"}
            onValueChanged={(value) => setInstallFee(value)}
          />
          <RowCheckBox
            name={"service_fee"}
            label={"Add Service Fee"}
            onValueChanged={(value) => setServiceFee(value)}
          />
          <RowCheckBox
            name={"overclock_fee"}
            label={"Add Overclock Fee"}
            onValueChanged={(value) => setOverclockFee(value)}
          />
        </div>
      )}
      <div className={"flex flex-row mb-4"}>
        <div className={"flex-1"}>
          {showDelete && (
            <Button
              variant={"outline"}
              color={"red"}
              className={"focus:z-10 py-1"}
              onClick={() => onDelete(minerQuoteItem)}
            >
              <TrashIcon className={"h-5 w-5"} />
            </Button>
          )}
        </div>
        <div className={"flex flex-row self-end"}>
          <Button
            onClick={onComplete}
            className={"focus:z-10"}
            loading={loading}
          >
            <CheckIcon className={"w-5 h-5"} />
          </Button>
          <div className={"px-[2px]"}></div>
          <Button
            variant={"outline"}
            color={"slate"}
            onClick={onCancel}
            className={"focus:z-10"}
            loading={loading}
          >
            <XMarkIcon className={"w-5 h-5"} />
          </Button>
        </div>
      </div>
    </div>
  );
}
