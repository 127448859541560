import React from "react";
import { Helmet } from "react-helmet";
import {
  BanknotesIcon,
  BuildingOfficeIcon,
  DocumentPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../utility/Utility";
import useUser from "../../hooks/useUser";
import { Button } from "../../components/Button";
import { PlusIcon } from "@heroicons/react/20/solid";
const stats = [
  { label: "active customers", value: 0 },
  { label: "active machines", value: 0 },
  { label: "Next invoice date 2/1/2024", value: null },
];
const actions = [
  {
    icon: DocumentPlusIcon,
    name: "Create a quote",
    href: "quotes",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    description:
      "Create a new quote for new or existing customers. Easily manage all of your created quotes in one place.",
  },
  {
    icon: UsersIcon,
    name: "Import your customers",
    href: "customers_new",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    description:
      "Import your existing customer list, or start fresh. Manage your client base with ease, and track each customer's machines in one place.",
  },
  {
    icon: BuildingOfficeIcon,
    name: "Invite your team",
    href: "#",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    description:
      "Invite other members of your team to collaborate and assist. Assign roles, and manage permissions across your account.",
  },
  {
    icon: BanknotesIcon,
    name: "Manage Billing",
    href: "/billing_new",
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
    description:
      "Review, adjust, and send out the invoices that get automatically created for each of your customers.",
  },
];
const reminders = [
  {
    id: 1,
    title: "Create a new quote to get started",
    href: "/quotes",
    preview:
      "Creating a quote is simple and allows you to easily turnaround new requests from your customers.",
  },
  {
    id: 2,
    title: "Importing your customer list is simple",
    href: "/customers_new",
    preview:
      "Easily upload your existing customer list using our CSV upload feature. Begin managing your entire business in Smart Host in under 5 minutes.",
  },
];

export default function HomePage() {
  const [user] = useUser();

  return (
    <>
      <Helmet>
        <title>Smart Host | Home</title>
      </Helmet>
      <div className="mt-4 pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Profile</h1>
          {/* Main 3 column grid */}
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              {/* Welcome panel */}
              <section aria-labelledby="profile-overview-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <h2 className="sr-only" id="profile-overview-title">
                    Profile Overview
                  </h2>
                  <div className="bg-white p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="sm:flex sm:space-x-5">
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                          <p className="text-sm font-medium text-gray-600">
                            Welcome back,
                          </p>
                          <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                            {user.first_name} {user.last_name}
                          </p>
                          <p className="text-sm font-medium text-gray-600">
                            {user.company_name}
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-center sm:mt-0">
                        <a
                          href="#"
                          className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Edit profile
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                    {stats.map((stat) => (
                      <div
                        key={stat.label}
                        className="px-6 py-5 text-center text-sm font-medium"
                      >
                        <span className="text-gray-900">{stat.value}</span>{" "}
                        <span className="text-gray-600">{stat.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              {/* Actions panel */}
              <section aria-labelledby="quick-links-title">
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                  <h2 className="sr-only" id="quick-links-title">
                    Quick links
                  </h2>
                  {actions.map((action, actionIdx) => (
                    <div
                      key={action.name}
                      className={classNames(
                        actionIdx === 0
                          ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                          : "",
                        actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                        actionIdx === actions.length - 2
                          ? "sm:rounded-bl-lg"
                          : "",
                        actionIdx === actions.length - 1
                          ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                          : "",
                        "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500",
                      )}
                    >
                      <div>
                        <span
                          className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            "inline-flex rounded-lg p-3 ring-4 ring-white",
                          )}
                        >
                          <action.icon className="h-6 w-6" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a href={action.href} className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            {action.name}
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          {action.description}
                        </p>
                      </div>
                      <span
                        className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-6 w-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>
                  ))}
                </div>
              </section>
            </div>

            {/* Right column */}
            <div className="grid grid-cols-1 gap-4">
              {/* reminders */}
              <section aria-labelledby="reminders-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <h2
                      className="text-base font-medium text-gray-900"
                      id="reminders-title"
                    >
                      Reminders
                    </h2>
                    <div className="mt-6 flow-root">
                      <ul
                        role="list"
                        className="-my-5 divide-y divide-gray-200"
                      >
                        {reminders.map((announcement) => (
                          <li key={announcement.id} className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                              <h3 className="text-sm font-semibold text-brand">
                                <a
                                  href={announcement.href}
                                  className="hover:underline focus:outline-none"
                                >
                                  {/* Extend touch target to entire panel */}
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  {announcement.title} &rarr;
                                </a>
                              </h3>
                              <p className="mt-1 line-clamp-3 text-sm text-gray-600">
                                {announcement.preview}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section aria-labelledby="reminders-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <h2
                      className="text-base font-medium text-gray-900"
                      id="reminders-title"
                    >
                      Billing
                    </h2>
                    <div className="mt-6 flow-root">
                      <p className={"text-sm text-gray-500 pb-2"}>
                        Create a customer to get started with billing
                      </p>
                      <Button>
                        <PlusIcon className={"h-5 w-5 mr-1"} />
                        Create a customer
                      </Button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
