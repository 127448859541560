import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useParams } from "react-router-dom";
import useCustomer from "../../../hooks/useCustomer";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CustomerBillingStats from "./CustomerBillingStats";
import InvoiceRail from "./InvoiceRail";
import InvoiceLinesForm from "./InvoiceLinesForm";
import useInvoice from "../../../hooks/useInvoice";

export default function ViewCustomerBilling() {
  // TODO: Refactor to use context
  const urlParams = useParams();
  const { customer_id } = urlParams;
  const [customer] = useCustomer(customer_id);
  // TODO: Base this on selected billing period
  const [invoice, refetch] = useInvoice(
    customer_id,
    customer?.current_billing_period_id, // TODO: HACK HACK HACK
  );

  if (!customer) {
    return (
      <div className={"miw-w-full mt-12 text-center"}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={"min-w-full pb-10 py-4"}>
      <div className={"space-y-8"}>
        <Breadcrumbs
          crumbs={[
            { name: "Customers", href: "/customers_new" },
            {
              name: `${customer.name}`,
              href: `/customers_new/${customer.id}`,
            },
            {
              name: "Billing",
              href: "#",
            },
          ]}
        />
        <CustomerBillingStats invoice={invoice} customer={customer} />
      </div>
      <div className="grid grid-cols-4 space-x-4 pt-2">
        <div
          className={
            "col-span-3 rounded-md mt-4 p-8 shadow-sm ring-1 ring-gray-900/5"
          }
        >
          <InvoiceLinesForm invoice={invoice} refetch={refetch} />
        </div>
        <div className="mt-4 p-4 rounded-md shadow-sm ring-1 ring-gray-900/5">
          <InvoiceRail customer={customer}></InvoiceRail>
        </div>
      </div>
    </div>
  );
}
