import { useEffect, useState } from "react";
import OperationsService from "../services/OperationsService";

export default function useUnmappedWorkers() {
  const [unmappedWorkers, setUnmappedWorkers] = useState();
  const svc = new OperationsService();

  const getUnmappedWorkers = async () => {
    const workers = await svc.getUnmappedWorkers();
    setUnmappedWorkers(workers);
  };

  useEffect(() => {
    getUnmappedWorkers();
  }, []);

  const refetch = async () => {
    await getUnmappedWorkers();
  };

  return [unmappedWorkers, refetch];
}
