import React from "react";
import AssetTimeline from "./AssetTimeline";

export default function AssetActionRail({ asset }) {
  return (
    <div className={"flex flex-col gap-4"}>
      <AssetTimeline asset={asset} />
    </div>
  );
}
