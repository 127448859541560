import React, { useState, useEffect } from "react";
import Sidebar from "../components/SidebarLayout";
import SupportPage from "./SupportPage";
import AssetsPage from "./AssetsPage";
import DashboardPage from "./DashboardPage";

const TAB_DISPLAY_NAMES = {
  dashboard: "Dashboard",
  support: "Request Support",
  assets: "Your Assets"
};

export default function RootPage({
  user,
  teams,
  billing_period,
  active_tab,
}) {
  const [activeTab, setActiveTab] = useState(active_tab);
  const [activeTeamId, setActiveTeamId] = useState(teams[0]?.id);

  const activeTeam = teams.find(x => x.id === activeTeamId);
  const changeTab = (tabName) => {
    setActiveTab(tabName);
  };
  const changeActiveTeam = (teamId) => {
    setActiveTeamId(teamId)
  };

  useEffect(() => {
    window.history.pushState("", "", activeTab);
    document.title = `${TAB_DISPLAY_NAMES[activeTab]} | CryptoCaddy Client Portal`;
  }, [activeTab]);
  return (
    <>
      <Sidebar user={user} activeTab={activeTab} setEndpoint={changeTab} setActiveTeam={changeActiveTeam} teams={teams}>
        <div className="max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="py-4">
            {activeTab === "dashboard" ? (
              <DashboardPage
                billing_period={billing_period}
                total_bill={activeTeam?.total_bill || 0}
                total_usage={activeTeam?.total_usage || 0}
                assets={activeTeam?.assets || []}
              />
            ) : activeTab === "assets" ? (
              <AssetsPage assets={activeTeam.assets} />
            ) : (
              <SupportPage />
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
}
