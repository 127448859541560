import React, { useState } from "react";
import RailsForm from "../components/RailsForm";
import Logo from "/public/images/cryptocaddy_inverted.png";

export default function NewPasswordPage({ user, reset_token }) {
  const [password, setPassword] = useState();
  const [verifyPassword, setVerifyPassword] = useState();

  const passwordsMatch = password === verifyPassword;
  const passwordStrong = password?.length > 6;
  const canSubmit = passwordsMatch;

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-20 w-auto text-center"
          src={Logo}
          alt="CryptoCaddy Logo"
        />
        <h3 className="mt-4 text-center text-xl font-bold tracking-tight text-gray-900">
          Set a new password for {user.email_address}
        </h3>
        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <RailsForm action="/set-new-password" method="POST">
              <input type="hidden" name="reset_token" value={reset_token} />
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <label
                htmlFor="verify-password"
                className="block text-sm font-medium text-gray-700 mt-6"
              >
                Repeat Password
              </label>
              <div className="mt-1">
                <input
                  id="verify-password"
                  name="verify-password"
                  type="password"
                  onChange={(e) => setVerifyPassword(e.target.value)}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={!canSubmit}
                  className={`disabled:bg-gray-100 disabled:text-gray-300 mt-6 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                  Set Password
                </button>
              </div>
              <div className="mt-4">
                {!passwordsMatch && (
                  <div className="text-sm text-red-600">
                    Passwords must match, and be greater than 6 characters.
                  </div>
                )}
              </div>
            </RailsForm>
          </div>
        </div>
      </div>
    </div>
  );
}
