import React, { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { classNames } from "../utility/Utility";

export default function SearchableDropdown({
  options,
  preSelectedId,
  onSelectedId,
  filterFn,
  idField,
  displayField,
  secondaryLabelFn,
  label,
  className,
  showLabel = true,
}) {
  const [query, setQuery] = useState("");
  const [selectedId, setSelectedId] = useState(preSelectedId);
  const selectedOption = options?.find((o) => o[idField] === selectedId);
  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return filterFn(option, query);
        });

  const onSelected = (option) => {
    const id = option[idField];
    setSelectedId(id);
    onSelectedId(id);
  };

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={(option) => onSelected(option)}
    >
      {showLabel && (
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </Combobox.Label>
      )}
      <div className="relative">
        <Combobox.Input
          className={classNames(
            "w-full border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6",
            className,
          )}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option) => {
            return option ? option[displayField] : null;
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option[idField]}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-brand text-white" : "text-gray-900",
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold",
                        )}
                      >
                        {option[displayField]}
                      </span>
                      <span
                        className={classNames(
                          "ml-2 truncate text-gray-500",
                          active ? "text-indigo-200" : "text-gray-500",
                        )}
                      >
                        {secondaryLabelFn(option)}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
