export default class AssetService {
  async getAsset(assetId) {
    const response = await fetch(`/assets_new/${assetId}?format=json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  }
}
