import React from "react";

export default function ButtonPane({
  title,
  children,
  onClick,
  href,
  buttonText,
}) {
  const buttonClasses =
    "cursor-pointer inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500";

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{children}</p>
        </div>
        <div className="mt-5">
          {href ? (
            <a target="_blank" className={buttonClasses} href={href}>
              {buttonText}
            </a>
          ) : (
            <button type="button" className={buttonClasses} onClick={onClick}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
