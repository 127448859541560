import { useEffect, useState } from "react";
import OperationsService from "../services/OperationsService";

export default function useBillingStats() {
  const [operations, setOperations] = useState();
  const svc = new OperationsService();

  const getOperations = async () => {
    const ops = await svc.getOperations();
    setOperations(ops);
  };

  useEffect(() => {
    getOperations();
  }, []);

  const refetch = async () => {
    await getOperations();
  };

  return [operations, refetch];
}
