import CustomerPageHeader from "./CustomerPageHeader";
import CustomerList from "./CustomerList";
import React, { useState } from "react";
import NewCustomerModal from "./NewCustomerModal";
import { Helmet } from "react-helmet";

export default function CustomersPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterTerm, setFilterTerm] = useState();

  return (
    <>
      <Helmet>
        <title>Smart Host | Customers</title>
      </Helmet>
      <div className={"pt-6 max-w-4xl mx-auto"}>
        <CustomerPageHeader
          setModalOpen={setModalOpen}
          onFilterTermUpdate={setFilterTerm}
        />
        <CustomerList filterTerm={filterTerm} />
        <NewCustomerModal modalOpen={modalOpen} setOpen={setModalOpen} />
      </div>
    </>
  );
}
