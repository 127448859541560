import React from "react";

export default function RailsForm({
  className,
  action,
  method,
  children,
  onSubmit,
}) {
  const getCsrfToken = () => {
    const node = document.querySelector("meta[name=csrf-token]");
    return node?.content;
  };
  const sanitizedMethod = ["get", "post"].includes(method.toLowerCase())
    ? method.toLowerCase()
    : null;

  return (
    <form
      action={action}
      method={sanitizedMethod || "post"}
      className={className}
      onSubmit={onSubmit}
    >
      {sanitizedMethod === null && (
        <input
          type="hidden"
          name="_method"
          value={method.toLowerCase()}
          autoComplete="off"
        />
      )}
      <input
        type="hidden"
        value={getCsrfToken()}
        name="authenticity_token"
        autoComplete="off"
      />
      {children}
    </form>
  );
}
