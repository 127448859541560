import React from "react";

export default function LinkPane({ title, children, href, linkText }) {
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            {children}
          </p>
        </div>
        <div className="mt-3 text-sm leading-6">
          <a
            href={href}
            target="_blank"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            {linkText}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
}
