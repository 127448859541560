import React from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import AssetStatusBadge from "../../components/AssetStatusBadge";
import WarrantyStatusBadge from "../../components/WarrantyStatusBadge";

const DescriptionRow = ({ title, value, onUpdateClick, showUpdate = true }) => {
  return (
    <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900">{title}</dt>
      <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <span className="flex-grow">{value}</span>
        {showUpdate && (
          <span className="ml-4 flex-shrink-0">
            <button
              type="button"
              className="rounded-md bg-white font-medium text-brand hover:text-brand-hover"
              onClick={onUpdateClick}
            >
              Update
            </button>
          </span>
        )}
      </dd>
    </div>
  );
};
export default function AssetDescriptionList({ asset, accountSettings }) {
  if (!asset) {
    return <LoadingIndicator />;
  }

  const cryptoCaddy = accountSettings?.features?.cryptocaddy;

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Asset Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Details and runtime information.
          </p>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <DescriptionRow
              title={"Asset Type"}
              value={asset.miner_configuration.name}
            />
            <DescriptionRow title={"Location"} value={asset.location?.name} />
            <DescriptionRow
              title={"Status"}
              value={
                <AssetStatusBadge
                  status={asset.aasm_state}
                  leased={asset.leased}
                />
              }
            />
            <DescriptionRow
              title={"Serial Number"}
              value={asset.serial_number}
            />
            <DescriptionRow
              title={"Alternate Name"}
              value={asset.customer_supplied_name}
            />
            {cryptoCaddy && (
              <>
                <DescriptionRow
                  title={"Warranty Status"}
                  value={<WarrantyStatusBadge asset={asset} />}
                  showUpdate={false}
                />
                <DescriptionRow
                  title={"Overclocking Purchased?"}
                  value={asset.overclocked ? "Yes" : "No"}
                  showUpdate={false}
                />
                {asset.overclocked && (
                  <DescriptionRow
                    title={"Needs Overclocking Installed?"}
                    value={asset.needs_oc_installed ? "Yes" : "No"}
                    showUpdate={false}
                  />
                )}
              </>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
}
