import React from "react";
import { classNames } from "../utility/Utility";

export default function ButtonGroup({ buttons, onButtonPress, selectedKey }) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {buttons.map((button, ix) => {
        return (
          <button
            type="button"
            key={button.key}
            onClick={() => onButtonPress(button)}
            className={classNames(
              button.key === selectedKey
                ? "bg-brand text-white"
                : "bg-white hover:bg-gray-50 text-gray-900",
              ix === 0
                ? "rounded-l-md"
                : ix === buttons.length - 1
                ? "-ml-px rounded-r-md"
                : "-ml-px",
              "relative inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10",
            )}
          >
            {button.text}
          </button>
        );
      })}
    </span>
  );
}
