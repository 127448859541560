import { classNames, usdFormatter } from "../../utility/Utility";
import React from "react";

export default function OperationsStats({ stats }) {
  return (
    <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
      <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:px-2 xl:px-0">
        {stats.map((stat, statIdx) => (
          <div
            key={stat.name}
            className={classNames(
              statIdx % 2 === 1
                ? "sm:border-l"
                : statIdx === 2
                ? "lg:border-l"
                : "",
              "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-4 sm:px-6 lg:border-t-0 xl:px-8",
            )}
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            {/*<dd*/}
            {/*  className={classNames(*/}
            {/*    stat.change_type === "negative"*/}
            {/*      ? "text-rose-600"*/}
            {/*      : "text-emerald-600",*/}
            {/*    "text-xs font-medium",*/}
            {/*  )}*/}
            {/*>*/}
            {/*  {stat.change}*/}
            {/*</dd>*/}
            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
              {stat.type === "money"
                ? usdFormatter.format(stat.value)
                : stat.type === "percent"
                ? `${stat.value}%`
                : stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
