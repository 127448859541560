import CustomerService from "../services/CustomerService";
import { useEffect, useState } from "react";

export default function useCustomers() {
  const [customers, setCustomers] = useState();
  const svc = new CustomerService();

  const getCustomers = async () => {
    const c = await svc.getCustomers();
    setCustomers(c);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const refetch = async () => {
    await getCustomers();
  };

  return [customers, refetch];
}
