import { getCsrfToken } from "../utility/Utility";
import { getJsonBody, getJsonHeaders } from "../utility/JsonUtil";

export default class QuoteService {
  async getQuote(id) {
    const response = await fetch(`/quotes/${id}?format=json`, {
      method: "GET",
      ...getJsonHeaders(),
    });
    return await response.json();
  }

  async getQuotes() {
    const response = await fetch(`/quotes?format=json`, {
      method: "GET",
      ...getJsonHeaders(),
    });
    return await response.json();
  }

  async markAsSigned(id) {
    const response = await fetch(`/quotes/${id}/mark_as_signed?format=json`, {
      method: "POST",
      ...getJsonHeaders(),
      ...getJsonBody({}),
    });
    return await response.json();
  }

  async markAsOrdered(id, supplierId) {
    const response = await fetch(`/quotes/${id}/mark_as_ordered?format=json`, {
      method: "POST",
      ...getJsonHeaders(),
      ...getJsonBody({ supplier_invoice_id: supplierId }),
    });
    return await response.json();
  }

  async markAsPaid(id) {
    const response = await fetch(`/quotes/${id}/mark_as_paid?format=json`, {
      method: "POST",
      ...getJsonHeaders(),
      ...getJsonBody({}),
    });
    return await response.json();
  }
}
