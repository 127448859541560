import { getCsrfToken } from "../utility/Utility";

export default function useDeleteInvoiceLine() {
  return async (invoice_line) => {
    const response = await fetch(
      `/invoice_lines/${invoice_line.id}?format=json`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          authenticity_token: getCsrfToken(),
        }),
      },
    );
    return await response.json();
  };
}
