import { useEffect, useState } from "react";
import AccountService from "../services/AccountService";

export default function useAccountSettings() {
  const [settings, setSettings] = useState();
  const svc = new AccountService();

  const getAccountSettings = async () => {
    const s = await svc.getAccountSettings();
    setSettings(s);
  };

  useEffect(() => {
    getAccountSettings();
  }, []);

  const refetch = async () => {
    await getAccountSettings();
  };

  return [settings, refetch];
}
