import React, { useMemo } from "react";
import moment from "moment";

const StatItem = ({ title, text }) => {
  return (
    <div className="p-8">
      <div className={"text-md text-gray-600"}>{title}</div>
      <div className={"text-2xl"}>{text}</div>
    </div>
  );
};

export default function AssetPreviewStats({ asset }) {
  const hashRateUnits = asset?.miner_configuration?.hash_rate_units ?? "TH/s";
  const expectedHashRate = asset?.miner_configuration?.hash_rate;
  const avgRate = useMemo(
    () => expectedHashRate - (Math.random() * 16).toFixed(0),
    [asset],
  );
  const uptimeDuration = moment.duration(asset?.uptime_seconds, "seconds");

  return (
    <div className={"grid grid-cols-2 grid-rows-2 gap-16"}>
      <StatItem
        title={"Expected Hashrate"}
        text={`${expectedHashRate} ${hashRateUnits}`}
      />
      <StatItem
        title={"Hashrate (avg)"}
        // text={`${asset?.hash_rate_30m} ${hashRateUnits}`}
        text={`${avgRate} ${hashRateUnits}`}
      />
      <StatItem
        title={"Hashrate (instant)"}
        // text={`${asset?.hash_rate_5s} ${hashRateUnits}`}
        text={`0 ${hashRateUnits}`}
      />
      <StatItem title={"Uptime"} text={uptimeDuration.humanize()} />
    </div>
  );
}
