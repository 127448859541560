import React, { useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import AssetUiButton from "../../components/AssetUiButton";
import ViewAssetPreviewModal from "../customers/view_customer/ViewAssetPreviewModal";
import ConfirmModal from "../../components/ConfirmModal";

export default function OperationsWorkerTable({ workers }) {
  const [showConfirmRestartModal, setShowConfirmRestartModal] = useState(false);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [previewAsset, setPreviewAsset] = useState(null);
  const [restartingAssetIds, setRestartingAssetIds] = useState([]);

  const onTroubledAssetClick = (asset) => {
    setPreviewAsset(asset);
    setShowAssetModal(true);
  };

  return (
    <div className="rounded-md">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-row sm:flex-1 sm:flex items-center">
          <div className="sm:mt-0 min-w-full flex flex-row justify-between items-center gap-x-4">
            <p>Troubled Assets</p>
            <button
              className="ml-auto mr-4 flex items-center gap-x-1 rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
              onClick={() => setShowConfirmRestartModal(true)}
            >
              <ArrowPathIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Restart all
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-12">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-12">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0"
                  >
                    Asset Number
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700"
                  >
                    Hash Rate (30m)
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700"
                  >
                    Hash Rate (30s)
                  </th>
                  <th
                    scope="col"
                    className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {workers?.map((worker) => (
                  <tr
                    key={worker.id}
                    onClick={() => onTroubledAssetClick(worker.asset)}
                  >
                    <td className="whitespace-nowrap relative py-2 pl-8 pr-3 text-sm text-gray-900 sm:pl gap-x-2 flex flex-row items-center">
                      {restartingAssetIds.includes(worker.asset.id) && (
                        <span className="absolute flex h-4 w-4 -ml-6">
                          <ArrowPathIcon className="animate-spin absolute inline-flex h-full w-full opacity-75"></ArrowPathIcon>
                        </span>
                      )}
                      <AssetUiButton asset={worker.asset} />
                      {worker.asset.asset_number}
                    </td>
                    <td className="whitespace-nowrap py-2 text-sm text-gray-500">
                      {worker.customer.first_name} {worker.customer.last_name}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      {worker.hash_rate_30m} {worker.hash_rate_unit}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      0 {worker.hash_rate_unit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ViewAssetPreviewModal
        asset={previewAsset}
        modalOpen={showAssetModal}
        setOpen={setShowAssetModal}
        onRestart={() => {
          setRestartingAssetIds([...restartingAssetIds, previewAsset.id]);
          setTimeout(() => {
            setRestartingAssetIds([
              ...restartingAssetIds.filter((x) => x !== previewAsset.id),
            ]);
          }, 20000);
        }}
      />
      <ConfirmModal
        title={"Restart all troubled workers?"}
        body={"This will restart all 11 troubled workers."}
        ctaText={"Restart all"}
        onConfirm={() => setRestartingAssetIds(workers.map((x) => x.asset.id))}
        setOpen={setShowConfirmRestartModal}
        modalOpen={showConfirmRestartModal}
      />
    </div>
  );
}
