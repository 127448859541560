export default function useFetch(method, endpoint) {
  const getCsrfToken = () => {
    const node = document.querySelector("meta[name=csrf-token]");
    return node?.content;
  };

  return async (body) => {
    const response = await fetch(`${endpoint}?format=json`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        authenticity_token: getCsrfToken(),
        ...body,
      }),
    });
    return await response.json();
  };
}
