import React, { useMemo } from "react";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

const getData = () => {
  const format = "h:mm a";
  const keys = [
    moment().subtract(6, "hours").format(format),
    moment().subtract(5.5, "hours").format(format),
    moment().subtract(5, "hours").format(format),
    moment().subtract(4, "hours").subtract(30, "minutes").format(format),
    moment().subtract(4, "hours").format(format),
    moment().subtract(3, "hours").subtract(30, "minutes").format(format),
    moment().subtract(3, "hours").format(format),
    moment().subtract(2, "hours").subtract(30, "minutes").format(format),
    moment().subtract(2, "hours").format(format),
    moment().subtract(1, "hours").subtract(30, "minutes").format(format),
    moment().subtract(1, "hours").format(format),
    moment().subtract(30, "minutes").format(format),
    moment().format(format),
  ];

  // Bit confusing, but this is meant to choose a random time for the machine to "go down"
  const downIndex = keys.length - (1 + Math.random() * 11);
  const points = keys.map((time, i) => {
    return {
      x: time,
      y: i < downIndex ? 82 + Math.random() * 8 : 0,
    };
  });

  return [
    {
      id: "uptime",
      color: "blue",
      data: points,
    },
  ];
};

export default function AssetHashrateChart({ asset }) {
  const data = useMemo(() => getData(), [asset]);

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 50, bottom: 75, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat={(y) => `${y.toFixed(2)} TH/s`}
      // tooltipFormat={(data) => `${data.y} uptime on ${data.x}`}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 2,
        tickPadding: 8,
        tickRotation: 90,
        legendOffset: 60,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Hashrate (TH/s)",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      // markers={[
      //   {
      //     axis: "y",
      //     legend: "95%",
      //     legendOrientation: "horizontal",
      //     lineStyle: {
      //       stroke: "green",
      //       strokeWidth: 1,
      //     },
      //     value: 95,
      //   },
      // ]}
    />
  );
}
