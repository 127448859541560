import React from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import EditableTextField from "../../../components/EditableTextField";
import { classNames, usdFormatter } from "../../../utility/Utility";

const ListItem = ({ title, body, editable }) => {
  return (
    <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900">{title}</dt>
      <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <span className="flex-grow">
          <EditableTextField
            className={"text-gray-500"}
            value={body}
            editable={editable}
          ></EditableTextField>
        </span>
      </dd>
    </div>
  );
};

export default function InfoPanel({ quote, className }) {
  if (!quote) {
    return <LoadingIndicator />;
  }

  return (
    <dl
      className={classNames(
        "grid grid-cols-2 gap-x-8 divide-gray-100",
        className,
      )}
    >
      <ListItem
        title={"Term"}
        body={`${quote.term} year${quote.term === 1 ? "" : "s"}`}
        editable={false}
      />
      <ListItem
        title={"Rate"}
        body={`$${quote.rate_kwh} / kWh`}
        editable={false}
      />
      <ListItem
        title={"Machines"}
        body={`${quote.total_miner_count} machine${
          quote.total_miner_count === 1 ? "" : "s"
        }`}
        editable={false}
      />
      <ListItem
        title={"Total Power"}
        body={`${(quote.total_watts / 1000).toFixed(1)} kW`}
        editable={false}
      />
      <ListItem
        title={"Monthly Hosting Cost"}
        body={`${usdFormatter.format(quote.estimated_monthly_cost)}`}
        editable={false}
      />
    </dl>
  );
}
