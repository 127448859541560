import React, { useEffect, useState } from "react";
import { usdFormatter } from "../../../utility/Utility";
import { Button } from "../../../components/Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import RowInput from "./RowInput";

const DropdownInput = ({ label, name, value, onChange }) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        className="focus:z-20 block w-full rounded-none border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-brand sm:text-sm sm:leading-6"
        defaultValue={value}
        onChange={(ev) => onChange(ev.target.value)}
      >
        <option value={1}>1 month</option>
        <option value={2}>2 months</option>
        <option value={2}>3 months</option>
      </select>
    </div>
  );
};

export default function HostingDepositEditForm({ quote, onCancel, onSave }) {
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (quote?.waive_first_month_hosting) {
      setQuantity(1);
    } else {
      setQuantity(2);
    }
  }, [quote]);
  const onComplete = () => {
    setLoading(true);
    const item = {
      ...quote,
      waive_first_month_hosting: quantity === 1,
    };
    if (onSave) onSave(item);
    setLoading(false);
  };

  return (
    <div className={"flex flex-col justify-center"}>
      <div className="flex -space-x-px my-6">
        <div className={"min-w-[300px]"}>
          <RowInput
            name={"name"}
            label={"Name"}
            value={"Hosting Deposit"}
            disabled={true}
          />
        </div>
        <div className={"min-w-[130px]"}>
          <DropdownInput
            name={"quantity"}
            label={"Quantity"}
            value={quote?.waive_first_month_hosting ? "1" : 2}
            onChange={setQuantity}
          />
        </div>
        <RowInput
          name={"cost"}
          value={usdFormatter.format(quote?.single_month_deposit)}
          label={"Cost"}
          disabled={true}
        />
        <RowInput
          name={"total"}
          value={`${usdFormatter.format(quote?.deposit)}`}
          label={"Total"}
          disabled={true}
        />
      </div>
      <div className={"flex flex-row mb-4 self-end"}>
        <Button onClick={onComplete} className={"focus:z-10"} loading={loading}>
          <CheckIcon className={"w-5 h-5"} />
        </Button>
        <div className={"px-[2px]"}></div>
        <Button
          variant={"outline"}
          color={"slate"}
          onClick={onCancel}
          className={"focus:z-10"}
          loading={loading}
        >
          <XMarkIcon className={"w-5 h-5"} />
        </Button>
      </div>
    </div>
  );
}
