import React from "react";
import MinerItemsTable from "./MinerItemsTable";
import DetailsPane from "./DetailsPane";
import LineItemsTable from "./LineItemsTable";

export default function QuoteBuilderPanel({
  quote,
  refetch,
  minerConfigurations,
}) {
  return (
    <>
      <DetailsPane quote={quote} />
      <MinerItemsTable
        quote={quote}
        minerConfigurations={minerConfigurations}
        refetch={refetch}
      />
      <LineItemsTable quote={quote} refetch={refetch} />
    </>
  );
}
