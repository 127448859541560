import React, { useState } from "react";
import { ArrowPathIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import AssetUiButton from "../../../components/AssetUiButton";
import LoadingIndicator from "../../../components/LoadingIndicator";
import AssignWorkerModal from "./AssignWorkerModal";

export default function UnmappedWorkersTable({ workers, refetch }) {
  const [showConfirmRestartModal, setShowConfirmRestartModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  if (!workers) return <LoadingIndicator />;
  const refetchWorkers = async () => {
    setRefreshing(true);
    await refetch();
    setTimeout(() => setRefreshing(false), 500);
  };

  return (
    <div className="rounded-md">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-row sm:flex-1 sm:flex items-center">
          <div className="sm:mt-0 min-w-full flex flex-row justify-between items-center gap-x-4">
            <p>Unmapped Workers</p>
            <button
              className="ml-auto mr-4 flex items-center gap-x-1 rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
              onClick={refetchWorkers}
            >
              <ArrowPathIcon
                className={`${refreshing ? "animate-spin" : ""} h-5 w-5`}
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-12">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-12">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-10 pr-3 text-left text-sm font-semibold text-gray-700"
                  >
                    IP Address
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0"
                  >
                    Pool URL
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700"
                  >
                    Worker Name
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700"
                  >
                    Hash Rate (30m)
                  </th>
                  <th
                    scope="col"
                    className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {workers?.map((worker) => (
                  <tr key={worker.id}>
                    <td className="whitespace-nowrap relative py-2 pr-3 text-sm text-gray-900 sm:pl gap-x-2 flex flex-row items-center">
                      <AssetUiButton asset={worker} />
                      {worker.ip_address}
                    </td>
                    <td className="whitespace-nowrap py-2 text-sm text-gray-500">
                      {worker.active_pool_url}
                    </td>
                    <td className="whitespace-nowrap py-2 text-sm text-gray-500">
                      {worker.active_pool_user}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      {worker.hash_rate_30m} {worker.hash_rate_unit}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      <button
                        className="ml-auto mr-4 flex items-center gap-x-1 rounded-md border border-brand px-3 py-2 text-sm font-semibold text-brand shadow-sm hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                        onClick={() => setShowConfirmRestartModal(true)}
                      >
                        Assign
                        <ArrowRightIcon
                          className="h-4 w-4"
                          aria-hidden="true"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AssignWorkerModal
        title={"Assign Worker"}
        setOpen={setShowConfirmRestartModal}
        modalOpen={showConfirmRestartModal}
        ctaText={"Assign"}
      />
    </div>
  );
}
