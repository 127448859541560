import { ArrowDownCircleIcon } from "@heroicons/react/20/solid";

export const navigation = [
  { name: "Home", href: "#" },
  { name: "Invoices", href: "#" },
  { name: "Clients", href: "#" },
  { name: "Expenses", href: "#" },
];
export const secondaryNavigation = [
  { name: "Last 7 days", href: "#", current: true },
  { name: "Last 30 days", href: "#", current: false },
  { name: "All-time", href: "#", current: false },
];
export const stats = [
  {
    name: "Revenue",
    value: "$45,932.00",
    change: "+4.75%",
    changeType: "positive",
  },
  {
    name: "Awaiting payment",
    value: "$12,787.00",
    change: "+13.02%",
    changeType: "positive",
  },
  {
    name: "Open Rate",
    value: "98.43%",
    change: "-1.39%",
    changeType: "negative",
  },
  {
    name: "Win Rate",
    value: "41.2%",
    change: "+10.18%",
    changeType: "positive",
  },
];
export const days = ["Today", "Yesterday", "Older"];
export const clients = [
  {
    id: 1,
    name: "Tuple",
    imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
    lastInvoice: {
      date: "December 13, 2022",
      dateTime: "2022-12-13",
      amount: "$2,000.00",
      status: "Overdue",
    },
  },
  {
    id: 2,
    name: "SavvyCal",
    imageUrl: "https://tailwindui.com/img/logos/48x48/savvycal.svg",
    lastInvoice: {
      date: "January 22, 2023",
      dateTime: "2023-01-22",
      amount: "$14,000.00",
      status: "Paid",
    },
  },
  {
    id: 3,
    name: "Reform",
    imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
    lastInvoice: {
      date: "January 23, 2023",
      dateTime: "2023-01-23",
      amount: "$7,600.00",
      status: "Paid",
    },
  },
];
