import React from "react";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";

const BaseWrapper = ({ children }) => {
  return (
    <div className={"px-4 sm:px-6 pb-4 min-w-full overflow-hidden"}>
      {children}
    </div>
  );
};

export default function Base({ hideNavbar = false }) {
  return hideNavbar ? (
    <BaseWrapper>
      <Outlet />
    </BaseWrapper>
  ) : (
    <Navbar>
      <BaseWrapper>
        <Outlet />
      </BaseWrapper>
    </Navbar>
  );
}
