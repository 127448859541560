import React, { useEffect, useState } from "react";
import InputValidated from "../../components/InputValidated";

export default function NewCustomerForm({ setValid }) {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const valid = firstName && lastName && email;
    setValid(valid);
  }, [firstName, lastName, email]);

  return (
    <div className={"space-y-12"}>
      <div className={"grid grid-cols-1 gap-y-4"}>
        <InputValidated
          onValueChange={(value) => setFirstName(value)}
          label={"First Name"}
          name={"customer[first_name]"}
          type={"name"}
        />
        <InputValidated
          onValueChange={(value) => setLastName(value)}
          label={"Last Name"}
          name={"customer[last_name]"}
          type={"name"}
        />
        <InputValidated
          label={"Company (optional)"}
          required={false}
          name={"customer[company_name]"}
          type={"name"}
        />
        <InputValidated
          onValueChange={(value) => setEmail(value)}
          label={"Email"}
          showValidationError={true}
          name={"customer[email]"}
          type={"email"}
        />
      </div>
    </div>
  );
}
