import React, { useEffect, useState } from "react";
import useCustomers from "../../../hooks/useCustomers";
import SimpleSelect from "../../../components/SimpleSelect";

export default function AssignWorkerForm({ onAssetChange }) {
  const [customers] = useCustomers();
  const validCustomers =
    customers?.filter((x) => x.assignable_assets.length > 0) || [];
  const [activeCustomer, setActiveCustomer] = useState();

  useEffect(() => {
    setActiveCustomer(validCustomers[0]);
  }, [customers]);

  return (
    <div className={"space-y-12"}>
      <div className={"grid grid-cols-1 gap-y-5"}>
        <SimpleSelect
          name={"customer"}
          options={validCustomers.map((x) => ({ value: x.id, label: x.name }))}
          label={"Select customer"}
          onChange={(selectedId) =>
            setActiveCustomer(validCustomers.find((x) => x.id === selectedId))
          }
        />
        {activeCustomer && (
          <SimpleSelect
            name={"customer"}
            options={activeCustomer.assignable_assets.map((x) => ({
              value: x.id,
              label: `Asset ${x.asset_number} (${x?.miner_configuration?.name})`,
            }))}
            label={"Select customer"}
          />
        )}
      </div>
    </div>
  );
}
