import React, { useState } from "react";
import TableRowDisplay from "../../quotes/view_quote/TableRowDisplay";
import { usdFormatter } from "../../../utility/Utility";
import LineItemEditForm from "../../quotes/view_quote/LineItemEditForm";
import { Button } from "../../../components/Button";
import LoadingIndicator from "../../../components/LoadingIndicator";
import useFetch from "../../../hooks/useFetch";
import useEditInvoiceLine from "../../../hooks/useEditInvoiceLine";
import useDeleteInvoiceLine from "../../../hooks/useDeleteInvoiceLine";

export default function InvoiceLinesForm({ invoice, refetch }) {
  const [newLineActive, setNewLineActive] = useState(false);
  const createNewInvoiceLine = useFetch("POST", "/invoice_lines");
  const editInvoiceLine = useEditInvoiceLine();
  const deleteInvoiceLine = useDeleteInvoiceLine();

  const onInvoiceLineFormSubmit = async (il, createNew = false) => {
    const body = {
      invoice_line: {
        ...il,
        invoice_id: invoice.id,
      },
    };
    if (createNew) {
      await createNewInvoiceLine(body);
      setNewLineActive(false);
    } else {
      await editInvoiceLine(il.id, body);
    }
    if (refetch) refetch();
  };

  const onInvoiceLineDelete = async (il) => {
    await deleteInvoiceLine(il);
    if (refetch) refetch();
  };

  if (!invoice) return <LoadingIndicator />;

  return (
    <table className="mt-12 w-full whitespace-nowrap text-left text-sm leading-6">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="border-b border-gray-200 text-gray-900">
        <tr>
          <th scope="col" className="px-0 py-3 font-semibold">
            Items
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Quantity
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Price
          </th>
          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {invoice.invoice_lines?.map((line) => (
          <TableRowDisplay
            key={line.id}
            name={line.name}
            description={line.description}
            quantity={line.quantity}
            price={usdFormatter.format(line.cost)}
            total={usdFormatter.format(line.quantity * line.cost)}
            lineItem={line}
            EditComponent={LineItemEditForm}
            showDelete={true}
            onSave={(il) => onInvoiceLineFormSubmit(il, false)}
            onDelete={(il) => onInvoiceLineDelete(il)}
          />
        ))}
        {!newLineActive && (
          <tr>
            <td colSpan={3}></td>
            <td
              className={
                "flex justify-items-end items-center pl-4 pt-4 text-right"
              }
            >
              <Button
                variant={"outline"}
                color={"brand"}
                onClick={() => setNewLineActive(true)}
              >
                Add Item
              </Button>
            </td>
          </tr>
        )}
        {newLineActive && (
          <tr>
            <td colSpan={4}>
              <LineItemEditForm
                onSave={(il) => onInvoiceLineFormSubmit(il, true)}
                onCancel={() => setNewLineActive(false)}
              />
            </td>
          </tr>
        )}
      </tbody>
      {invoice.invoice_lines?.length > 0 && (
        <tfoot>
          <tr>
            <th
              scope="row"
              className="px-0 pb-0 pt-12 font-normal text-gray-700 sm:hidden"
            >
              Subtotal
            </th>
            <th
              scope="row"
              colSpan={3}
              className="hidden px-0 pb-0 pt-12 text-right font-normal text-gray-700 sm:table-cell"
            >
              Subtotal
            </th>
            <td className="pb-0 pl-8 pr-0 pt-12 text-right tabular-nums text-gray-900">
              {usdFormatter.format(invoice.subtotal)}
            </td>
          </tr>
          {invoice.invoice_tax_lines?.map((tax_line) => (
            <tr key={tax_line.id}>
              <th
                scope="row"
                className="pt-4 font-normal text-gray-700 sm:hidden"
              >
                {tax_line.name}
              </th>
              <th
                scope="row"
                colSpan={3}
                className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
              >
                {tax_line.name}
              </th>
              <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
                {usdFormatter.format(tax_line.percent * invoice.subtotal)}
              </td>
            </tr>
          ))}
          <tr>
            <th
              scope="row"
              className="pt-4 font-normal text-gray-700 sm:hidden"
            >
              Total
            </th>
            <th
              scope="row"
              colSpan={3}
              className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
            >
              Total
            </th>
            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
              {usdFormatter.format(invoice.total)}
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
}
