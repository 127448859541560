import React, { useEffect, useState } from "react";
import useCustomers from "../../../hooks/useCustomers";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidated from "../../../components/InputValidated";
import Dropdown from "../../../components/Dropdown";

export const ExistingCustomerOnboardForm = ({ quote, setValid }) => {
  const [firstName, setFirstName] = useState(quote?.first_name);
  const [lastName, setLastName] = useState(quote?.last_name);
  const [companyName, setCompanyName] = useState(quote?.company_name);
  const [email, setEmail] = useState(quote?.email);
  const [rate, setRate] = useState(quote?.rate_kwh);
  const [term, setTerm] = useState(quote?.term);
  const [customerId, setCustomerId] = useState();
  const [termStartsAt, setTermStartsAt] = useState(
    quote?.estimated_online_date,
  );

  const [customers, refetch] = useCustomers();

  useEffect(() => {
    const valid = firstName && lastName && email && rate && term;
    setValid(valid);
  }, [firstName, lastName, email, rate, term, termStartsAt]);

  const onCustomerSelected = (selected) => {
    const customer = customers.find((x) => x.id === selected.value);
    if (!customer) {
      return;
    }
    setFirstName(customer.first_name);
    setLastName(customer.last_name);
    setCompanyName(customer.company_name);
    setEmail(customer.email);
    setCustomerId(customer.id);
  };

  if (!customers) {
    return (
      <div className={"my-4"}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={"space-y-12 my-4"}>
      <div className={"grid grid-cols-1 gap-y-4"}>
        <input hidden name={"quote[version]"} value={"2"} />
        <input hidden name={"quote[first_name]"} value={firstName} />
        <input hidden name={"quote[last_name]"} value={lastName} />
        <input hidden name={"quote[company_name]"} value={companyName} />
        <input hidden name={"quote[email]"} value={email} />
        <input hidden name={"quote[term_starts_at]"} value={termStartsAt} />
        <input hidden name={"quote[customer_id]"} value={customerId} />
        <input hidden name={"quote[customer_option]"} value={"assign"} />
        <label className="block text-sm text-left font-medium leading-6 text-gray-900">
          Select customer
        </label>
        <Dropdown
          fullWidth={true}
          includeBlank={true}
          style={"smarthost"}
          items={customers.map((customer) => ({
            title: customer.name,
            subtitle: customer.company_name,
            value: customer.id,
          }))}
          onSelectionChange={onCustomerSelected}
        />
        <InputValidated
          onValueChange={(value) => setTermStartsAt(value)}
          label={"Term Starts At"}
          name={"quote[term_starts_at]"}
          defaultVal={termStartsAt}
          type={"date"}
        />
      </div>
    </div>
  );
};

export default ExistingCustomerOnboardForm;
