import React from "react";
import {
  classNames,
  QUOTE_CLOSED,
  QUOTE_DRAFT,
  QUOTE_ORDERED,
  QUOTE_PAID,
  QUOTE_SIGNED,
} from "../utility/Utility";

export const statuses = {
  [QUOTE_CLOSED]: {
    classes: "text-green-700 bg-green-50 ring-green-600/20",
    name: "Closed",
  },
  [QUOTE_DRAFT]: {
    classes: "text-gray-600 bg-gray-50 ring-gray-500/10",
    name: "Draft",
  },
  [QUOTE_PAID]: {
    classes: "text-indigo-600 bg-indigo-50 ring-indigo-500/10",
    name: "Paid",
  },
  [QUOTE_ORDERED]: {
    classes: "text-cyan-600 bg-cyan-50 ring-cyan-500/10",
    name: "Ordered",
  },
  [QUOTE_SIGNED]: {
    classes: "text-blue-700 bg-blue-50 ring-blue-600/10",
    name: "Signed",
  },
};
export default function QuoteStatusBadge({ state }) {
  const stateKey = state?.toLowerCase();
  if (!stateKey) return null;

  return (
    <div
      className={classNames(
        statuses[stateKey]?.classes,
        "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
      )}
    >
      {statuses[stateKey]?.name}
    </div>
  );
}
