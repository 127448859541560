import React from "react";

export default function SimpleSelect({
  name,
  label,
  defaultValue,
  onChange,
  options,
  includeBlank = false,
  style = "smarthost",
}) {
  const onChangeSelected = (ev) => {
    if (onChange) onChange(ev.target.value);
  };

  return (
    <>
      {label && (
        <label
          htmlFor={label}
          className="block text-sm text-left font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      <select
        id={name}
        name={name}
        className={`focus:z-20 block w-full rounded-none border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:${
          style === "smarthost" ? "ring-brand" : "ring-indigo-600"
        } sm:text-sm sm:leading-6`}
        defaultValue={defaultValue}
        required={true}
        onChange={onChangeSelected}
      >
        {includeBlank && (
          <option hidden disabled selected value="">
            {" "}
            -- Select an Option --{" "}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
}
