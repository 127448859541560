import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
export default function UnmappedWorkersHeader() {
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <Breadcrumbs
          crumbs={[
            { name: "Operations", href: "/operations_new" },
            {
              name: `Unmapped Workers`,
              href: "#",
            },
          ]}
        />
      </div>
    </div>
  );
}
