import ReactOnRails from "react-on-rails";
import SignInPage from "../bundles/frontend/pages/SignInPage";
import DashboardPage from "../bundles/frontend/client_portal/pages/DashboardPage";
import NewPasswordPage from "../bundles/frontend/pages/NewPasswordPage";
import AssetsPage from "../bundles/frontend/client_portal/pages/AssetsPage";
import SupportPage from "../bundles/frontend/client_portal/pages/SupportPage";
import RootPage from "../bundles/frontend/client_portal/pages/RootPage";

// saas pages
import App from "../bundles/frontend/pages/App";

// This is how react_on_rails can see the Page in the browser.
ReactOnRails.register({
  SignInPage,
  DashboardPage,
  NewPasswordPage,
  AssetsPage,
  SupportPage,
  RootPage,
  App,
});
