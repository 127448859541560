import React from "react";

export default function Tooltip({ content, children }) {
  return (
    <div className="group">
      <div className="border-b border-dashed border-gray-200">{children}</div>
      <div className="relative flex flex-col">
        <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
          <span className="relative z-10 p-2 text-sm leading-none text-white bg-black shadow-lg rounded w-auto whitespace-no-wrap">
            {content}
          </span>
          <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
        </div>
      </div>
    </div>
  );
}
