import { useEffect, useState } from "react";
import CustomerService from "../services/CustomerService";

export default function useCustomer(customerId) {
  const [customer, setCustomer] = useState();
  const svc = new CustomerService();

  const getCustomer = async (id) => {
    const customer = await svc.getCustomer(id);
    setCustomer(customer);
  };

  useEffect(() => {
    getCustomer(customerId);
  }, [customerId]);

  const refetch = async () => {
    await getCustomer(customerId);
  };

  return [customer, refetch];
}
