import React from "react";
import UnmappedWorkersHeader from "./UnmappedWorkersHeader";
import UnmappedWorkersTable from "./UnmappedWorkersTable";
import useUnmappedWorkers from "../../../hooks/useUnmappedWorkers";

export default function UnmappedWorkersPage() {
  const [unmappedWorkers, refetch] = useUnmappedWorkers();
  console.log(unmappedWorkers);

  return (
    <div className={"mt-4"}>
      <UnmappedWorkersHeader />
      <div className={"mt-4"}>
        <UnmappedWorkersTable
          workers={unmappedWorkers?.unmapped_workers}
          refetch={refetch}
        />
      </div>
    </div>
  );
}
