import QuoteStatusBadge from "../../../components/QuoteStatusBadge";
import {
  BuildingOfficeIcon,
  EnvelopeIcon,
  Square2StackIcon as Square2StackIconOutline,
  Square2StackIcon as Square2StackIconSolid,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import {
  DATE_FORMAT,
  QUOTE_CLOSED,
  QUOTE_DRAFT,
  QUOTE_ORDERED,
  QUOTE_PAID,
  QUOTE_SIGNED,
  usdFormatter,
} from "../../../utility/Utility";
import OnboardCustomerModal from "./OnboardCustomerModal";
import ConfirmModal from "../../../components/ConfirmModal";
import QuoteService from "../../../services/QuoteService";
import moment from "moment";
import MarkAsOrderedModal from "./MarkAsOrderedModal";

const getConfirmModalInfoFromState = (quote) => {
  if (!quote) return {};

  switch (quote.aasm_state) {
    case QUOTE_DRAFT:
      return {
        title: "Mark Quote as Customer Signed?",
        body: "Are you sure you want to mark this quote as customer signed?",
        ctaText: "Confirm",
      };
    case QUOTE_SIGNED:
      return {
        title: "Create Invoice for Quote?",
        body: "This will create an invoice in Quickbooks. Are you sure you want to continue?",
        ctaText: "Confirm",
      };
  }
};

export default function SummaryPanel({ quote, refetch }) {
  const [emailCopied, setEmailCopied] = useState(false);
  const [closeDealModalOpen, setCloseDealModalOpen] = useState(false);
  const [confirmcloseDealModalOpen, setConfirmcloseDealModalOpen] =
    useState(false);
  const [
    confirmOrderedcloseDealModalOpen,
    setConfirmOrderedcloseDealModalOpen,
  ] = useState(false);
  const quoteService = new QuoteService();

  const openConfirmationModal = () => {
    setConfirmcloseDealModalOpen(true);
  };
  const onConfirmation = async (supplierId = null) => {
    switch (quote.aasm_state) {
      case QUOTE_DRAFT:
        await quoteService.markAsSigned(quote.id);
        break;
      case QUOTE_SIGNED:
        await quoteService.markAsPaid(quote.id);
        break;
      case QUOTE_PAID:
        await quoteService.markAsOrdered(quote.id, supplierId);
        break;
    }
    await refetch();
  };
  const confirmModalInfo = getConfirmModalInfoFromState(quote);

  return (
    <>
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Total
            </dt>
            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
              {usdFormatter.format(quote.total)}
            </dd>
          </div>
          <div className="flex-none self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            <QuoteStatusBadge state={quote?.aasm_state} />
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <UserCircleIcon
                className="h-6 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="text-sm font-medium leading-6 text-gray-900">
              {quote.name}
            </dd>
          </div>
          {quote?.company_name && (
            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
              <dt className="flex-none">
                <span className="sr-only">Status</span>
                <BuildingOfficeIcon
                  className="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd className="text-sm leading-6 text-gray-500">
                {quote.company_name}
              </dd>
            </div>
          )}
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Order Date</span>
              <EnvelopeIcon
                className="h-6 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <div className={"flex gap-x-2"}>
                {quote?.email}{" "}
                <span>
                  {emailCopied ? (
                    <>
                      <span className="text-xs pl-6 pt-1 text-gray-400 absolute">
                        Copied!
                      </span>
                      <Square2StackIconSolid className="h-6 w-5 text-gray-400" />
                    </>
                  ) : (
                    <Square2StackIconOutline
                      className="h-6 w-5 text-gray-400 cursor-pointer"
                      onClick={async () => {
                        await navigator.clipboard.writeText(quote.email);
                        setEmailCopied(true);
                        setTimeout(5000, () => setEmailCopied(false));
                      }}
                    />
                  )}
                </span>
              </div>
            </dd>
          </div>
        </dl>
        {quote.aasm_state === QUOTE_DRAFT && (
          <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={openConfirmationModal}
            >
              Mark Customer Signed <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        )}
        {quote.aasm_state === QUOTE_SIGNED && (
          <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={openConfirmationModal}
            >
              Create Invoice for Quote <span aria-hidden="true">&rarr;</span>
            </a>
            <div className="text-xs font-semibold leading-6 text-gray-500">
              Signed on {moment(quote.signed_at).format(DATE_FORMAT)}
            </div>
          </div>
        )}
        {quote.aasm_state === QUOTE_PAID && (
          <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={() => setConfirmOrderedcloseDealModalOpen(true)}
            >
              Mark Quote as Ordered <span aria-hidden="true">&rarr;</span>
            </a>
            <div className="text-xs font-semibold leading-6 text-gray-500">
              Invoice Created on {moment(quote.paid_at).format(DATE_FORMAT)}
            </div>
          </div>
        )}
        {quote.aasm_state === QUOTE_ORDERED && (
          <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={() => setCloseDealModalOpen(true)}
            >
              Close Deal <span aria-hidden="true">&rarr;</span>
            </a>
            <div className="text-xs font-semibold leading-6 text-gray-500 mt-1">
              Quote ordered on {moment(quote.ordered_at).format(DATE_FORMAT)} as
              part of supplier invoice <em>{quote.supplier_invoice_id}</em>
            </div>
          </div>
        )}
        {quote.aasm_state === QUOTE_CLOSED && (
          <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
            <a
              href={`/customers/${quote?.customer_id}/contracts/${quote?.contract_id}`}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              View Created Contract <span aria-hidden="true">&rarr;</span>
            </a>
            <div className="text-xs font-semibold leading-6 text-gray-500 mt-1">
              Quote closed on {moment(quote.closed_at).format(DATE_FORMAT)}
            </div>
          </div>
        )}
      </div>
      <ConfirmModal
        modalOpen={confirmcloseDealModalOpen}
        setOpen={setConfirmcloseDealModalOpen}
        title={confirmModalInfo?.title}
        body={confirmModalInfo?.body}
        ctaText={confirmModalInfo?.ctaText}
        onConfirm={onConfirmation}
      />
      <MarkAsOrderedModal
        modalOpen={confirmOrderedcloseDealModalOpen}
        setOpen={setConfirmOrderedcloseDealModalOpen}
        onConfirm={(supplierId) => onConfirmation(supplierId)}
      />
      <OnboardCustomerModal
        quote={quote}
        setOpen={setCloseDealModalOpen}
        modalOpen={closeDealModalOpen}
      />
    </>
  );
}
