import React, { useEffect, useState } from "react";
import InputValidated from "../../../components/InputValidated";

export const OnboardCustomerForm = ({ quote, setValid }) => {
  const [firstName, setFirstName] = useState(quote?.first_name);
  const [lastName, setLastName] = useState(quote?.last_name);
  const [companyName, setCompanyName] = useState(quote?.company_name);
  const [email, setEmail] = useState(quote?.email);
  const [rate, setRate] = useState(quote?.rate_kwh);
  const [term, setTerm] = useState(quote?.term);
  const [termStartsAt, setTermStartsAt] = useState(
    quote?.estimated_online_date,
  );

  useEffect(() => {
    const valid = firstName && lastName && email && rate && term;
    setValid(valid);
  }, [firstName, lastName, email, rate, term, termStartsAt]);

  return (
    <div className={"space-y-12"}>
      <div className={"grid grid-cols-1 gap-y-4"}>
        <input hidden name={"quote[version]"} value={"2"} />
        <input hidden name={"quote[customer_option]"} value={"create"} />
        <InputValidated
          onValueChange={(value) => setFirstName(value)}
          label={"First Name"}
          name={"quote[first_name]"}
          type={"name"}
          defaultVal={firstName}
        />
        <InputValidated
          onValueChange={(value) => setLastName(value)}
          label={"Last Name"}
          name={"quote[last_name]"}
          type={"name"}
          defaultVal={lastName}
        />
        <InputValidated
          label={"Company (optional)"}
          required={false}
          name={"quote[company_name]"}
          type={"name"}
          defaultVal={companyName}
        />
        <InputValidated
          onValueChange={(value) => setEmail(value)}
          label={"Email"}
          showValidationError={true}
          name={"quote[email]"}
          type={"email"}
          defaultVal={email}
        />
        <InputValidated
          onValueChange={(value) => setRate(value)}
          label={"Rate ($ / kWh)"}
          name={"quote[rate_kwh]"}
          defaultVal={rate}
          type={"number"}
        />
        <InputValidated
          onValueChange={(value) => setTerm(value)}
          label={"Term (years)"}
          name={"quote[term]"}
          defaultVal={term}
          type={"number"}
          step={0.1}
        />
        <InputValidated
          onValueChange={(value) => setTermStartsAt(value)}
          label={"Term Starts At"}
          name={"quote[term_starts_at]"}
          defaultVal={termStartsAt}
          type={"date"}
        />
      </div>
    </div>
  );
};

export default OnboardCustomerForm;
