import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../utility/Utility";

// items should be array of { title, subtitle, value }
export default function Dropdown({
  label,
  items,
  onSelectionChange,
  className,
  defaultVal,
  style = "tailwind",
  includeBlank = false,
  fullWidth = false,
}) {
  const [selected, setSelected] = useState(
    includeBlank && !defaultVal ? null : defaultVal ?? items[0],
  );

  const setDropdownValue = (selectedIndex) => {
    const item = items.find((i) => i.value === selectedIndex);
    setSelected(item);
    if (onSelectionChange) onSelectionChange(item);
  };

  return (
    <Listbox value={selected} onChange={setDropdownValue}>
      {({ open }) => (
        <div className={classNames("flex flex-row justify-start", className)}>
          <Listbox.Label className="block text-sm self-center font-medium leading-6 text-gray-900">
            {label}
          </Listbox.Label>
          <div className={`relative ml-2 ${fullWidth ? "flex-1" : ""}`}>
            <Listbox.Button
              className={`relative min-w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 ${
                style === "tailwind"
                  ? "focus:ring-indigo-500"
                  : "focus:ring-brand"
              } sm:text-sm sm:leading-6`}
            >
              <span className="inline-flex w-full truncate pr-4">
                <span className="truncate">
                  {selected?.title ?? "Select an option..."}
                </span>
                {/* <span className="ml-2 truncate text-gray-500">{selected.subtitle}</span> */}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {items.map((item) => (
                  <Listbox.Option
                    key={item.value}
                    className={({ active }) =>
                      classNames(
                        active
                          ? `${
                              style === "tailwind"
                                ? "bg-indigo-600"
                                : "bg-brand"
                            } text-white`
                          : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={item.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "truncate",
                            )}
                          >
                            {item.title}
                          </span>
                          <span
                            className={classNames(
                              active
                                ? `${
                                    style === "tailwind"
                                      ? "text-indigo-200"
                                      : "text-brand-hover"
                                  }`
                                : "text-gray-500",
                              "ml-2 truncate",
                            )}
                          >
                            {item.subtitle}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active
                                ? "text-white"
                                : `${
                                    style === "tailwind"
                                      ? "text-indigo-600"
                                      : "text-brand"
                                  }`,
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
