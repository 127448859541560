import React from "react";
import Routes from "./routes/RouteComponent";
import { Helmet } from "react-helmet";

export const App = (props) => (
  <div className={"pb-8"}>
    <Helmet>
      <title>Smart Host | Run your hosting business with ease</title>
    </Helmet>
    {Routes(props)}
  </div>
);

export default App;
