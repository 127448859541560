import { getCsrfToken } from "./Utility";

export function getJsonHeaders() {
  return {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
}

export function getJsonBody(body) {
  return {
    body: JSON.stringify({
      authenticity_token: getCsrfToken(),
      ...body,
    }),
  };
}
