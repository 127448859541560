import { ComputerDesktopIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function AssetUiButton({ asset }) {
  return (
    <a
      href={`http://root:root@${
        asset.ip_address ? asset.ip_address : `cc${asset.asset_number}.local`
      }`}
      className={"bg-gray-600 p-2 rounded-md text-white inline-flex"}
      target={"_blank"}
      rel={"noreferrer"}
    >
      <ComputerDesktopIcon className={"h-4 w-4"} />
    </a>
  );
}
