import React from "react";
import RailsForm from "../../components/RailsForm";
import { Helmet } from "react-helmet";

export default function CreateAccount() {
  return (
    <>
      <Helmet>
        <title>Smart Host | Create an account</title>
      </Helmet>
      <div className="flex min-h-full flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto text-center"
            src="images/smarthost_logo_center.svg"
            alt="CryptoCaddy Logo"
          />
          <h2 className="mt-4 text-center text-2xl font-bold tracking-tight text-gray-900">
            Create a New Account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-400">
            Start your 30-day free trial. Cancel any time.
          </p>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10">
            <RailsForm className="space-y-6" action="/accounts" method="POST">
              <div className={"grid grid-cols-2 gap-x-4"}>
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="company_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company name
                </label>
                <div className="mt-1">
                  <input
                    id="company_name"
                    name="company_name"
                    type="text"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                  />
                </div>
              </div>

              <div className={"grid grid-cols-2 gap-x-4"}>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="confirm_password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm password
                  </label>
                  <div className="mt-1">
                    <input
                      id="confirm_password"
                      name="confirm_password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-brand py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-hover focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                >
                  Create Account
                </button>
              </div>
            </RailsForm>
            <div className="text-sm mt-8 text-center min-w-full">
              <span className={"text-gray-400"}>Already have an account?</span>
              <a
                href="/login"
                className="ml-1 font-medium text-brand hover:text-brand-hover"
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
