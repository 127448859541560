import { useEffect, useState } from "react";
import InvoiceService from "../services/InvoiceService";

export default function useInvoice(customerId, billingPeriodId) {
  const [invoice, setInvoice] = useState();
  const svc = new InvoiceService();

  const getInvoice = async (custId, bpId) => {
    const invoice = await svc.getInvoice(custId, bpId);
    setInvoice(invoice);
  };

  useEffect(() => {
    getInvoice(customerId, billingPeriodId);
  }, [customerId, billingPeriodId]);

  const refetch = async () => {
    await getInvoice(customerId, billingPeriodId);
  };

  return [invoice, refetch];
}
