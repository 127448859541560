import React from "react";

const StatusChip = ({ status }) => {
  const colors =
    status === "Active"
      ? "bg-green-100 text-green-800"
      : "bg-gray-100 text-gray-800";
  return (
    <span
      className={`inline-flex items-center rounded px-2 py-0.5 text-xs font-medium ${colors}`}
    >
      {status}
    </span>
  );
};

export default function AssetsTable({ assets }) {
  return (
    <>
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Your Assets</h1>
      </div>
      {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
			<button
				type="button"
				className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
			>
				Export as CSV
			</button>
		</div> */}
      <div className="mt-5 flow-root">
        {/* Activity table (small breakpoint and up) */}
        <div className="flex flex-col mx-auto">
          <div className="min-w-full align-middle shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-50 text-left text-sm font-semibold text-gray-900">
                  <th className="px-6 py-3" scope="col">
                    Asset Number
                  </th>
                  <th className="px-6 py-3" scope="col">
                    Serial Number
                  </th>
                  <th className="px-6 py-3" scope="col">
                    Manufacturer
                  </th>
                  <th className="px-6 py-3" scope="col">
                    Miner Type
                  </th>
                  <th className="px-6 py-3" scope="col">
                    Hash Rate
                  </th>
                  <th className="px-6 py-3" scope="col">
                    Rate ($ / kWh)
                  </th>
                  <th className="px-6 py-3" scope="col">
                    Factory Power (W)
                  </th>
                  <th className="px-6 py-3" scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {assets.map((asset) => (
                  <tr
                    key={asset.asset_number}
                    className="bg-white hover:bg-gray-50 text-gray-900 whitespace-nowrap text-left"
                  >
                    <td className="px-6 py-4">{asset.asset_number}</td>
                    <td className="px-6 py-4">{asset.serial_number}</td>
                    <td className="px-6 py-4">{asset.manufacturer}</td>
                    <td className="px-6 py-4">{asset.miner_type}</td>
                    <td className="px-6 py-4">
                      {asset.stock_hash_rate}{" "}
                      <span className="text-gray-500">
                        {asset.hash_rate_units || "TH/s"}
                      </span>
                    </td>
                    <td className="px-6 py-4">${asset.rate_kwh}</td>
                    <td className="px-6 py-4">
                      {Math.round(asset.factory_watts)} W
                      {asset.overclocked && (
                        <span className="ml-2 inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800">
                          OC'd to ~{Math.round(asset.estimated_watts)} W
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <StatusChip status={asset.leased ? "Active" : "Inactive"} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
