import { getCsrfToken } from "../utility/Utility";

export default function useDeleteQuoteLine() {
  return async (quote_line) => {
    const response = await fetch(`/quote_lines/${quote_line.id}?format=json`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        authenticity_token: getCsrfToken(),
      }),
    });
    return await response.json();
  };
}
