import React from "react";

export default function SimpleLinkAction({ title, text, ctaText, href }) {
  return (
    <div className="bg-white shadow sm:rounded-lg border-t-4 border-brand">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{text}</p>
        </div>
        <div className="mt-3 text-sm leading-6">
          <a
            href={href}
            className="font-semibold text-brand hover:text-brand-hover"
          >
            {ctaText}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
}
