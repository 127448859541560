import React, { useRef, useState } from "react";
import { classNames } from "../utility/Utility";

export default function EditableTextField({
  value,
  name,
  label,
  type,
  placeholder,
  onSave,
  editable,
  className,
}) {
  const onChange = (value) => {
    if (onSave) onSave(value);
  };
  const [editing, setEditing] = useState(false);
  const id = `special-click-input-${name}`;

  const hideOnClickOutside = (getEditing) => {
    const element = document.getElementById(id);
    console.log(element);
    const outsideClickListener = (event) => {
      console.log(element.contains(event.target));
      console.log(editing);
      if (!element.contains(event.target) && getEditing()) {
        //&& isVisible(element)) {
        // or use: event.target.closest(selector) === null
        setEditing(false);
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener("click", outsideClickListener);
    };

    document.addEventListener("click", outsideClickListener);
  };

  const isVisible = (elem) =>
    !!elem &&
    !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length); // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js

  const onClick = () => {
    if (editable) {
      setEditing(true);
      hideOnClickOutside(() => editing);
    }
  };

  return (
    <>
      <div
        className={classNames(
          "w-full min-w-0 flex-1 bg-gray-100",
          editing ? "" : "hidden",
        )}
      >
        <label htmlFor={name} className="text-gray-500">
          {label}
        </label>
        <input
          id={id}
          type={type}
          name={name}
          onChange={(ev) => onChange(ev.target.value)}
          className="relative overflow-ellipsis block w-full rounded-none rounded-bl-md disabled:text-gray-500 disabled:bg-gray-100 border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6"
          placeholder={placeholder}
          value={value}
        />
      </div>
      <div
        className={classNames(className, editable ? "bg-gray-100" : "")}
        onClick={() => onClick()}
      >
        {value}
      </div>
    </>
  );
}
