import React from "react";
export default function LoadingIndicator({ size }) {
  let sizeClasses = "h-6 w-6";

  switch (size) {
    case "sm": {
      sizeClasses = "h-4 w-4";
      break;
    }
    case "lg": {
      sizeClasses = "h-8 w-8";
      break;
    }
    case "xl": {
      sizeClasses = "h-12 w-12";
      break;
    }
  }
  return (
    <div
      className={`inline-block ${sizeClasses} animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
}
