import { useEffect, useState } from "react";
import QuoteService from "../services/QuoteService";

export default function useQuotesDashboard() {
  const [data, setData] = useState({});
  const svc = new QuoteService();

  const getQuotes = async () => {
    const q = await svc.getQuotes();
    setData(q);
  };

  useEffect(() => {
    getQuotes();
  }, []);

  const refetch = async () => {
    await getQuotes();
  };

  return [data, refetch];
}
