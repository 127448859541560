import React, { useEffect, useState } from "react";
import InputValidated from "../../../components/InputValidated";
import moment from "moment";

const DEFAULT_RATE = 0.08;
const DEFAULT_TERM = 1;

export const EditQuoteForm = ({ setValid, quote }) => {
  const [firstName, setFirstName] = useState(quote.first_name);
  const [lastName, setLastName] = useState(quote.last_name);
  const [email, setEmail] = useState(quote.email);
  const [rate, setRate] = useState(quote.rate_kwh);
  const [term, setTerm] = useState(quote.term);
  const [companyName, setCompanyName] = useState(quote.company_name);
  const [orderDate, setOrderDate] = useState(quote.order_date);
  const [estimatedOnlineDate, setEstimatedOnlineDate] = useState(
    quote.estimated_online_date,
  );
  console.log(term);

  useEffect(() => {
    const valid = firstName && lastName && email && rate && term;
    setValid(valid);
  }, [firstName, lastName, email, rate, term]);

  return (
    <div className={"space-y-12"}>
      <div className={"grid grid-cols-1 gap-y-5"}>
        <div className={"grid grid-cols-2 gap-x-3"}>
          <InputValidated
            onValueChange={(value) => setFirstName(value)}
            label={"First Name"}
            name={"quote[first_name]"}
            type={"name"}
            defaultVal={firstName}
          />
          <InputValidated
            onValueChange={(value) => setLastName(value)}
            label={"Last Name"}
            name={"quote[last_name]"}
            type={"name"}
            defaultVal={lastName}
          />
        </div>
        <InputValidated
          label={"Company (optional)"}
          required={false}
          name={"quote[company_name]"}
          type={"name"}
          defaultVal={companyName}
        />
        <InputValidated
          onValueChange={(value) => setEmail(value)}
          label={"Email"}
          showValidationError={true}
          name={"quote[email]"}
          type={"email"}
          defaultVal={email}
        />
        <div className={"grid grid-cols-2 gap-x-3"}>
          <InputValidated
            onValueChange={(value) => setOrderDate(value)}
            label={"Order Date"}
            name={"quote[order_date]"}
            defaultVal={orderDate}
            type={"date"}
          />
          <InputValidated
            onValueChange={(value) => setEstimatedOnlineDate(value)}
            label={"Estimated Online Date"}
            name={"quote[estimated_online_date]"}
            defaultVal={estimatedOnlineDate}
            type={"date"}
          />
        </div>
        <div className={"grid grid-cols-2 gap-x-3"}>
          <InputValidated
            onValueChange={(value) => setRate(value)}
            label={"Rate ($ / kWh)"}
            name={"quote[rate_kwh]"}
            defaultVal={rate}
            type={"number"}
            step={"any"}
          />
          <InputValidated
            onValueChange={(value) => setTerm(value)}
            label={"Term (years)"}
            name={"quote[term]"}
            defaultVal={term}
            type={"number"}
            step={0.1}
          />
        </div>
      </div>
    </div>
  );
};

export default EditQuoteForm;
