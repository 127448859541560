import React, { useEffect, useState } from "react";
import InputValidated from "../../components/InputValidated";

const DEFAULT_RATE = 0.08;
const DEFAULT_TERM = 1;

export const NewQuoteForm = ({ setValid }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [rate, setRate] = useState(DEFAULT_RATE);
  const [term, setTerm] = useState(DEFAULT_TERM);

  useEffect(() => {
    const valid = firstName && lastName && email && rate && term;
    setValid(valid);
  }, [firstName, lastName, email, rate, term]);

  return (
    <div className={"space-y-12"}>
      <div className={"grid grid-cols-1 gap-y-4"}>
        <input hidden name={"quote[version]"} value={"2"} />
        <InputValidated
          onValueChange={(value) => setFirstName(value)}
          label={"First Name"}
          name={"quote[first_name]"}
          type={"name"}
        />
        <InputValidated
          onValueChange={(value) => setLastName(value)}
          label={"Last Name"}
          name={"quote[last_name]"}
          type={"name"}
        />
        <InputValidated
          label={"Company (optional)"}
          required={false}
          name={"quote[company_name]"}
          type={"name"}
        />
        <InputValidated
          onValueChange={(value) => setEmail(value)}
          label={"Email"}
          showValidationError={true}
          name={"quote[email]"}
          type={"email"}
        />
        <InputValidated
          onValueChange={(value) => setRate(value)}
          label={"Rate ($ / kWh)"}
          name={"quote[rate_kwh]"}
          defaultVal={DEFAULT_RATE}
          type={"number"}
          step={0.000001}
        />
        <InputValidated
          onValueChange={(value) => setTerm(value)}
          label={"Term (years)"}
          name={"quote[term]"}
          defaultVal={DEFAULT_TERM}
          type={"number"}
          step={0.1}
        />
      </div>
    </div>
  );
};

export default NewQuoteForm;
