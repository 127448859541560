import React from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { InboxIcon } from "@heroicons/react/24/outline";

export default function InvoiceRail({ customer }) {
  if (!customer) return <LoadingIndicator />;

  return (
    <div className={"mt-4"}>
      {customer.previous_invoices.length === 0 ? (
        <div
          className={
            "min-w-full gap-2 mt-24 flex flex-col items-center justify-center text-gray-400"
          }
        >
          <InboxIcon className={"h-8 w-8"} />
          <p>No previous invoices</p>
        </div>
      ) : (
        <>
          <h3>Billing history</h3>
          <ul role="list" className="divide-y divide-gray-100">
            {customer.previous_invoices.map((invoice) => (
              <li
                key={invoice.id}
                className="flex items-center justify-between gap-x-6 py-5"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {invoice.total}
                    </p>
                    <div className={"flex flex-row items-center space-x-2"}>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        Invoice #{invoice.id}
                      </p>
                      <svg
                        viewBox="0 0 2 2"
                        className="h-0.5 w-0.5 fill-current mt-1"
                      >
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {invoice.period}
                      </p>
                    </div>
                  </div>
                </div>
                <a
                  href={invoice.href}
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  View
                </a>
              </li>
            ))}
          </ul>
          <a
            href="#"
            className="flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            View all
          </a>
        </>
      )}
    </div>
  );
}
