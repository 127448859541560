import { useEffect, useState } from "react";
import InvoiceService from "../services/InvoiceService";

export default function useBillingStats(billingPeriod) {
  const [invoices, setInvoices] = useState();
  const svc = new InvoiceService();

  const getInvoices = async (billingPeriodId) => {
    const invoices = await svc.getInvoices(billingPeriodId);
    setInvoices(invoices);
  };

  useEffect(() => {
    getInvoices(billingPeriod?.id);
  }, [billingPeriod]);

  const refetch = async () => {
    await getInvoices(billingPeriod?.id);
  };

  return [invoices, refetch];
}
