import React from "react";
import TableCard from "../TableCard";

const TableHeader = ({ children }) => {
  return (
    <th
      className="bg-gray-50 px-6 py-3 text-sm font-semibold text-gray-900"
      scope="col"
    >
      {children}
    </th>
  );
};

export default function UsageTable({ assets }) {
  return (
    <div className="mt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Current Period Power Usage
          </h1>
        </div>
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Export as CSV
          </button>
        </div> */}
      </div>
      <div className="mt-5 flow-root">
        {/* Activity table (small breakpoint and up) */}
        <div className="flex flex-col mx-auto">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="text-left">
                  <TableHeader>Asset Number</TableHeader>
                  <TableHeader> Period Usage (kWh)</TableHeader>
                  <TableHeader> Miner Type </TableHeader>
                  <TableHeader> Factory Power (W)</TableHeader>
                  <TableHeader> Rate ($ / kWh)</TableHeader>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {assets.map((asset) => (
                  <tr
                    key={asset.asset_number}
                    className="bg-white hover:bg-gray-50"
                  >
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                      <div className="flex">
                        <p className="truncate text-gray-500 group-hover:text-gray-900">
                          CC{asset.asset_number}
                        </p>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4  text-sm text-gray-900">
                      {Math.round(asset.actual_usage)} kWh
                    </td>
                    <td className="whitespace-nowrap px-6 py-4  text-sm text-gray-500">
                      <span className="font-medium text-gray-900">
                        {asset.miner_type}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                      {Math.round(asset.factory_watts)} W
                      {asset.overclocked && (
                        <span className="ml-2 inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800">
                          OC'd to ~{Math.round(asset.estimated_watts)} W
                        </span>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4  text-sm text-gray-900">
                      ${asset.rate_kwh}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
