import React from "react";
import { Fragment, useState } from "react";
import useOperations from "../../hooks/useOperations";
import OperationsStats from "./OperationsStats";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import OperationsWorkerTable from "./OperationsWorkerTable";
import SimpleLinkAction from "../../components/SimpleLinkAction";

export const OperationsPage = () => {
  const [operations, refetch] = useOperations();

  return (
    <div className={"grid grid-cols-5"}>
      <div className="relative isolate overflow-hidden col-span-4">
        <p className={"py-6"}>Operations</p>
        {/* Stats */}
        <OperationsStats stats={operations?.stats || []} />
        <div className="space-y-16 py-10 xl:space-y-20 border-1 rounded-md border-slate-200">
          <OperationsWorkerTable workers={operations?.troubled_workers || []} />
          {/* Recent activity table */}
          {/* Recent client list*/}
        </div>
      </div>
      <div className={"pl-10 pt-16 -mr-16"}>
        <SimpleLinkAction
          ctaText={"Map 15 workers"}
          text={
            "There are 15 workers that are active, but are not mapped to assets."
          }
          href={"operations_new/unmapped_workers"}
          title={"Unmapped Workers"}
        />
      </div>
    </div>
  );
};

export default OperationsPage;
