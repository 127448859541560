import React from "react";
import Dropdown from "../../../components/Dropdown";
import { usdFormatter } from "../../../utility/Utility";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../components/Button";
import LoadingIndicator from "../../../components/LoadingIndicator";
import BillingPeriodDropdown from "../../../components/BillingPeriodDropdown";

const customerBillingStats = (machineCount) => {
  const hostingCost = machineCount * (180 + Math.random() * 25);
  const downtimeCredit = 50 + Math.random() * machineCount;

  return [
    {
      name: "Machines",
      stat: machineCount ? `${machineCount} machines` : "",
    },
    {
      name: "Total Hosting Cost",
      stat: machineCount ? usdFormatter.format(hostingCost) : "",
    },
    {
      name: "Downtime Credit",
      stat: machineCount ? usdFormatter.format(downtimeCredit) : "",
    },
    {
      name: "Net Amount Due",
      stat: machineCount
        ? usdFormatter.format(hostingCost - downtimeCredit)
        : "",
    },
  ];
};
export default function CustomerBillingStats({ invoice, customer }) {
  if (!invoice || !customer) return <LoadingIndicator />;

  return (
    <div className={"relative"}>
      <div className={"absolute -top-14 right-0"}>
        <BillingPeriodDropdown billing_periods={customer.billing_periods} />
      </div>
      <div
        className={
          "text-lg gap-x-4 md:text-3xl flex flex-row min-w-full items-end"
        }
      >
        Invoice #{invoice.id}
        <span className={" text-base text-slate-500"}>
          Due on {invoice.invoice_date}
        </span>
        <span className={"flex-1"}>
          {invoice.is_preview && (
            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
              Preview
            </span>
          )}
        </span>
        <span className={"justify-self-end"}>
          <Button
            href={`/invoices_new/${invoice.id}?format=pdf${
              invoice.is_preview ? "&preview=true" : ""
            }`}
            color={"brand"}
            target={"_blank"}
          >
            <EyeIcon className="h-5 w-5 text-white pr-1" aria-hidden="true" />
            View Invoice
          </Button>
        </span>
      </div>
      {/*<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">*/}
      {/*  {customerBillingStats(customer?.assets?.length).map((item) => (*/}
      {/*    <div*/}
      {/*      key={item.name}*/}
      {/*      className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"*/}
      {/*    >*/}
      {/*      <dt className="truncate text-sm font-medium text-gray-500">*/}
      {/*        {item.name}*/}
      {/*      </dt>*/}
      {/*      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">*/}
      {/*        {item.stat}*/}
      {/*      </dd>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</dl>*/}
    </div>
  );
}
