import React, { useState } from "react";
import { classNames, usdFormatter } from "../../utility/Utility";
import InvoiceGrid from "./InvoiceGrid";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import useBillingStats from "../../hooks/useBillingStats";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import BillingPeriodDropdown from "../../components/BillingPeriodDropdown";

const SecondaryNavigation = ({ isFinal, billing_periods }) => {
  return (
    <header className="py-6">
      <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
        <h1 className="text-base font-semibold leading-7 text-gray-900">
          Billing
        </h1>
        <BillingPeriodDropdown billing_periods={billing_periods} />
        <div className={"flex-1"}></div>
        <div className={"flex flex-row space-x-2"}>
          {isFinal && (
            <a
              href={`billing/${
                billing_periods ? billing_periods[0].id : ""
              }/download_qb_invoices?format=csv`}
              type="button"
              className="text-gray-600 inline-flex w-full justify-center rounded-md bg-white py-2 px-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            >
              <CloudArrowDownIcon className={"h-5 w-5"} />
            </a>
          )}
          {!isFinal && (
            <a
              href={"/billing_new?is_final=true"}
              className="inline-flex disabled:bg-gray-200 w-full justify-center rounded-md bg-brand hover:bg-brand-hover px-6 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand sm:col-start-2"
              type={"button"}
            >
              Finalize <span className="pl-1"> &rarr;</span>
            </a>
          )}
          {isFinal && (
            <a
              href={"/billing_new?is_final=true"}
              className="whitespace-nowrap inline-flex disabled:bg-gray-200 w-full justify-center rounded-md bg-brand hover:bg-brand-hover px-6 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand sm:col-start-2"
              type={"button"}
            >
              Send All
            </a>
          )}
        </div>
      </div>
    </header>
  );
};
const BillingStats = ({ stats }) => {
  return (
    <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
      <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
        {stats.map((stat, statIdx) => (
          <div
            key={stat.name}
            className={classNames(
              statIdx % 2 === 1
                ? "sm:border-l"
                : statIdx === 2
                ? "lg:border-l"
                : "",
              "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8",
            )}
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            {/*<dd*/}
            {/*  className={classNames(*/}
            {/*    stat.change_type === "negative"*/}
            {/*      ? "text-rose-600"*/}
            {/*      : "text-emerald-600",*/}
            {/*    "text-xs font-medium",*/}
            {/*  )}*/}
            {/*>*/}
            {/*  {stat.change}*/}
            {/*</dd>*/}
            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
              {stat.type === "currency"
                ? usdFormatter.format(stat.value)
                : stat.type === "percent"
                ? `${stat.value}%`
                : stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export const BillingPage = (props) => {
  const { billing_period } = props;
  const [search] = useSearchParams();
  const isFinal = search.get("is_final") === "true";
  const [billingStats] = useBillingStats(billing_period);
  const { stats, invoices, billing_periods } = billingStats || {};
  const [showSendConfirmModal, setShowSendConfirmModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>Smart Host | Billing</title>
      </Helmet>
      <div className="relative isolate overflow-hidden">
        <SecondaryNavigation
          isFinal={isFinal}
          billing_periods={billing_periods}
        />
        <BillingStats stats={stats || []} />
      </div>
      <div className="space-y-16 py-16 xl:space-y-20">
        <InvoiceGrid invoices={invoices} isFinal={isFinal} />
      </div>
    </>
  );
};

export default BillingPage;
