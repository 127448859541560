import React from "react";
import moment from "moment";
import InfoPanel from "./InfoPanel";

export default function DetailsPane({ quote }) {
  return (
    <dl className="mt-2 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
      <div className="sm:pr-4">
        <dt className="inline text-gray-500">Ordered on</dt>{" "}
        <dd className="inline text-gray-700">
          <time>{moment(quote.order_date).format("LL")}</time>
        </dd>
      </div>
      <div className="mt-2 sm:mt-0 sm:pl-4">
        <dt className="inline text-gray-500">Estimated online by</dt>{" "}
        <dd className="inline text-gray-700">
          <time>{moment(quote.estimated_online_date).format("LL")}</time>
        </dd>
      </div>
      <InfoPanel className={"col-span-2 mt-6"} quote={quote} />
    </dl>
  );
}
