import { classNames } from "../utility/Utility";

const ACTIVE = "active";
const NEEDS_REPAIR = "waiting_for_repair";
const DEGRADED = "degraded";
const OUT_FOR_REPAIR = "out_for_repair";
const NOTIFY_CUSTOMER = "notify_customer";
import React from "react";

export default function AssetStatusBadge({ status, leased }) {
  let className = "text-green-700 bg-green-50 ring-green-600/20";
  let name = "Active";

  if (status === ACTIVE && !leased) {
    className = "text-gray-600 bg-gray-50 ring-gray-500/10";
    name = "Inactive";
  } else if (status === NEEDS_REPAIR) {
    className = "text-yellow-700 bg-yellow-50 ring-yellow-600/10";
    name = "Needs Repair";
  } else if (status === OUT_FOR_REPAIR) {
    className = "text-blue-700 bg-blue-50 ring-blue-600/10";
    name = "Out for Repair";
  } else if (status === DEGRADED) {
    className = "text-indigo-600 bg-indigo-50 ring-indigo-500/10";
    name = "Degraded";
  } else if (status === NOTIFY_CUSTOMER) {
    className = "text-red-600 bg-red-50 ring-red-500/10";
    name = "Notify Customer";
  }

  return (
    <div
      className={classNames(
        className,
        "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset w-fit",
      )}
    >
      {name}
    </div>
  );
}
