import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AssetHashrateChart from "./AssetHashrateChart";
import AssetPreviewStats from "./AssetPreviewStats";
import AssetUiButton from "../../../components/AssetUiButton";
import { Button } from "../../../components/Button";

export default function ViewAssetPreviewModal({
  modalOpen = false,
  setOpen,
  asset,
  onRestart,
}) {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onResetClick = () => {
    setLoading(true);
    setTimeout(() => {
      setOpen(false);
      setLoading(false);
      onRestart();
    }, 650);
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1000px] sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900"
                    >
                      Asset {asset?.asset_number} (
                      {asset?.miner_configuration?.name})
                    </Dialog.Title>
                    <div className="my-8">
                      <div className={"grid grid-cols-2"}>
                        <div className={"min-h-[400px] min-w-[500px]"}>
                          <AssetHashrateChart asset={asset} />
                        </div>
                        <div className={"min-h-[400px] min-w-[500px]"}>
                          <AssetPreviewStats asset={asset} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-8 sm:mt-6 w-full flex items-end gap-2">
                  <div className={"flex-1"}></div>
                  <AssetUiButton asset={asset} />
                  <Button href={"#"} variant={"outline"} color={"slate"}>
                    View Logs
                  </Button>
                  <button
                    className="inline-flex disabled:bg-gray-200 justify-center rounded-md bg-brand hover:bg-brand-hover px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand sm:col-start-2"
                    onClick={onResetClick}
                  >
                    {!loading ? "Restart Machine" : <LoadingIndicator />}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
