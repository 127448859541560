export default class AccountService {
  async getAccountSettings() {
    const response = await fetch(`/me/account_settings?format=json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  }
}
