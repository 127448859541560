import React from "react";
import Dropdown from "./Dropdown";
import LoadingIndicator from "./LoadingIndicator";

export default function BillingPeriodDropdown({ billing_periods }) {
  if (!billing_periods) return <LoadingIndicator />;

  return (
    <Dropdown
      label={""}
      className={"min-w-[300px] self-end"}
      includeBlank={false}
      style={"smarthost"}
      items={billing_periods?.map((x, ix) => ({
        title: `${
          ix === 0
            ? `Current Period (${x.start_date} - ${x.end_date})`
            : `${x.start_date} - ${x.end_date}`
        }`,
        value: x.id,
      }))}
    />
  );
}
